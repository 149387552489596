// Customizable Area Start
import React from "react";

import { Box,Checkbox,Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  RadioGroup,
  TextField,
  Typography, } from "@mui/material";

import "./ProjectCard.css";
import "react-multi-carousel/lib/styles.css";
import { projectCarouselData, status } from "./types";
import { ProjectCarousel } from "./ProjectCarousel.web";

import KanbanBoardController, { Props } from "./KanbanBoardController";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {unCheckIcon,checkedIcon, success,} from "./assets"
import { Close } from "@material-ui/icons";
import CustomSelect from "./AddMembersModal.web";
import { Button } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";



export default class KanbanBoard extends KanbanBoardController {
  constructor(props: Props) {
    super(props);
  }

  renderChangeListView=()=>{
    return(
      <Box component="div" style={webStyle.OptionBox}>
                  <Box component="div" style={webStyle.singleOptionBox}>
                    <Box
                      data-test-id="button1"
                      onClick={() => this.handleToggle("Kanban View")}
                      component="div"
                      style={{
                        ...webStyle.PersonalInformationOption,
                        backgroundColor: this.state.toggle ? "#E0EDF0" : "",
                        color: this.state.toggle ? "#325962" : "#64748B",
                      }}
                    >
                      Kanban View
                    </Box>
                    <Box
                      data-test-id="button2"
                      onClick={() => this.handleToggle("List View")}
                      component="div"
                      style={{
                        ...webStyle.BusinessInformationOption,
                        backgroundColor: this.state.toggle ? "" : "#E0EDF0",
                        color: this.state.toggle ? "#64748B" : "#325962",
                      }}
                    >
                      List View
                    </Box>
                  </Box>
                </Box>
    )
  }

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
        <MyProfileHeader showSidebar={true} navigation={this.props.navigation}>
        {this.state.messageToggle && (
              <MessageBox
                message={this.state.tostifymessage}
                status={this.state.status}
              />
            )}
          <Grid container sx={webStyle.gridCont} gap={2}>
            <Box style={{ margin: "2rem 2rem 2rem 2.9rem", width: "100%" }}>
              <Box style={{fontSize:"24px" ,fontFamily:"Poppins", fontWeight:700 , color:"#0F172A", lineHeight:"32px" }}>Rhino Stan Smith HQ roof renovation</Box>
              <Box style={webStyle.headerSection2}>
                {this.renderChangeListView()}
                 <Box
                  style={{
                    color: '#94A3B8',
                    border: '1px solid #CBD5E1',
                    borderRadius: '8px',
                    padding: '10px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleMenuOpen}
                >
                  <Typography>Bulk Action</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
                
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  <MenuItem
                    onClick={(e) => this.handleActionClick('Change Status', e)}
                    sx={{
                      borderRadius: '10px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  >
                    Change Status <KeyboardArrowRightIcon />
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.handleActionClick('Assign User', '')}
                    sx={{
                      borderRadius: '10px',
                    }}
                  >
                    Assign User
                  </MenuItem>
                </Menu>
                
                <Menu
                  anchorEl={this.state.subMenuAnchorEl}
                  open={Boolean(this.state.subMenuOpen)}
                  onClose={this.handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                    },
                  }}
                >
                  {['To Do', 'In Progress', 'Completed', 'Blocked', 'Archived'].map((status) => (
                  <MenuItem
                  key={status}
                  data-test-id="sub-menu-id"
                  onClick={() => this.handleSubMenuClick(status)}
                  sx={{
                    borderRadius: '8px',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: '#ffffff',
                    },
                  }}
                >
                  <Typography
                    style={{
                      ...webStyle.statusStyle,
                      backgroundColor: this.getStatusBackgroundColor(status), 
                    }}
                  >
                    {status}
                  </Typography>
                </MenuItem>
                  ))}
                </Menu>
                
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "0" }}
                >
                  <Box style={webStyle.sort}>
                    <Typography
                      variant="body1"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Sort By:
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <select
                        style={{
                          border: "none",
                          color: "#237182",
                          fontWeight: 600,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          backgroundColor: "#f0f5f7",
                          marginBottom: "2px",
                          marginRight: "5px",
                          paddingRight: "30px",
                          appearance: "none",
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                        }}
                        name="sort"
                        data-test-id="sort-id"
                      >
                        <option value="a-z">Due Date </option>
                        <option value="z-a">Z-A</option>
                      </select>

                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                          fontSize: "20px",
                          color: "#237182",
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2292 7.50155L9.99583 10.7349L6.7625 7.50155C6.60681 7.34551 6.39543 7.25781 6.175 7.25781C5.95457 7.25781 5.74319 7.34551 5.5875 7.50155C5.2625 7.82655 5.2625 8.35155 5.5875 8.67655L9.4125 12.5016C9.7375 12.8266 10.2625 12.8266 10.5875 12.5016L14.4125 8.67655C14.7375 8.35155 14.7375 7.82655 14.4125 7.50155C14.0875 7.18488 13.5542 7.17655 13.2292 7.50155Z"
                            fill="#237182"
                          />
                        </svg>
                      </span>
                    </Box>
                  </Box>
                </Box>

                <Box onClick={this.handleFilter} data-test-id="filter-id" style={webStyle.filterStyle}>
                 <FilterListIcon width="15px" height="10px" /> <Typography style={{color:'#94A3B8' ,textAlign:"center" , paddingLeft:"0.2rem"}}>Filter</Typography> 
                </Box>

                <Box>
                  <TextField
                    variant="outlined"
                    placeholder="Search here..."
                    data-test-id="search-input"
                    onClick={() => {
                      this.handleSearch("test");
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              height: "20px",
                              color: "#94A3B8",
                              width: "20px",
                            }}
                          />
                        </InputAdornment>
                      ),

                      style: {
                        width: "460px",
                        height: "6rem",
                        borderRadius: "8px",
                      },
                    }}
                    inputProps={{
                    style: {
                    padding: "10px 16px", 
                     },
                    maxLength: 50,
                    }}
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "white",
                      height: "46px",
                      marginRight: "0.9rem",
                    }}
                  />
                </Box>
              </Box>

              <Grid container style={{width:"100%"}}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Box>

                  {this.renderStatusData("To DO",5, "#60A5FA")}

                    {this.renderCard(
                      "Room/Area Type",
                      "Assemble and Position Kitchen Cabinets",
                      "Low",
                      "#60A5FA",
                      "0/8",
                      "Michael Lee",
                      "07/12/20 - 07/09/24",
                      "#DBEAFE"
                    )}
                    {this.renderCard(
                      "Room/Area Type",
                      "Install Electrical Wiring",
                      "High",
                      "#DC2626",
                      "2/5",
                      "John Doe",
                      "08/01/24 - 08/15/24",
                      "#FEE2E2"
                      )}
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Box>

                  {this.renderStatusData("In Progress",3, "#F59E0B")}

                    {this.renderCard(
        "Room/Area Type",
        "Install Electrical Wiring",
        "Mid", "#D97706", "2/5",
        "John Doe","08/01/24 - 08/15/24", "#FEF3C7" )
      }
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Box>

                  {
                  this.renderStatusData("Completed",2, "#10B981")
                  }

                    {
                    this.renderCard(
        "Room/Area Type","Install Electrical Wiring","Low","#FF0000",
        "2/5",
        "John Doe",
        "08/01/24 - 08/15/24",
        "#DBEAFE"
      )
      }
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Box>
                  {
                  this.renderStatusData("Blocked",2, "#F87171")
                  }

                    
                    {this.renderCard(
        "Room/Area Type",
        "Install Electrical Wiring",
        "High","#FF0000","2/5","John Doe",
        "08/01/20 - 08/15/20",
        "#FEE2E2")
      }
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {this.renderModal()}
            <CustomToast icon={<img src={success} />} />
          </Grid>
        </MyProfileHeader>
      </ThemeProvider>
      </>
    );
  }

  renderStatusData = (status: string, count: number, colorCode: string) => {
    return (
      <Box style={webStyle.outerBoxStatus}>
        <Typography
          style={{
            ...webStyle.statusCircleStyle,
            backgroundColor: colorCode,
          }}
        ></Typography>
        <Box style={{ fontFamily: "poppins" }}>{status}</Box>
        <Box style={webStyle.statusCountStyle}>{count}</Box>
      </Box>
    );
  };

  renderCard = (
    roomType: string,
    taskName: string,
    priority: string,
    priorityColor: string,
    checklist: string,
    assignedTo: string,
    dateRange: string,
    priorityBgColor:string
  ) => {
    return (
      <Grid style={webStyle.cardOuterContainer}>
        <Box style={webStyle.roomStyleMainCard}>
          <Box style={webStyle.roomStyleCard}><Typography style={webStyle.roomStatusCircleStyle}></Typography>
          <Box style={webStyle.roomTypeStyle}>{roomType}</Box></Box>
          <Box>
            <Checkbox
                       data-test-id="tasklist-change-id"
                      checked={this.state.isTaskListSelected}
                      onChange={this.handleChangetaskListSelect}
                      
                      icon={
                        <img
                          src={unCheckIcon} style={{ width: 16, height: 16 }} />
                      }
                      checkedIcon={  <img
                          src={checkedIcon}
                          style={{ width: 16, height: 16 }}
                        />}
                      style={
                        { color: "#64748B" }
                      }
                  
                    /></Box>
        </Box>
        <Box style={webStyle.roomNameStyle}>{taskName}</Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Box style={{...webStyle.priorityStyle,backgroundColor:priorityBgColor}}>
            <Typography
              style={{
                ...webStyle.roomStatusCircleStyle,
                backgroundColor: priorityColor,
              }}
            ></Typography>
            <Box
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Poppins",
                color: priorityColor,
              }}
            >
              {priority}
            </Box>
            <KeyboardArrowDownIcon
              style={{ fontSize: "12px", color:priorityColor }}
            />
          </Box>
          <Typography
            style={{
              color: "#237182",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChecklistIcon style={{ width: "16px", height: "16px" }} />
            <Typography style={{ fontSize: "12px" }}>{checklist}</Typography>
          </Typography>
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Box style={webStyle.containerDataStyle}>
            <PersonOutlineOutlinedIcon width="16px" height="16px" />{" "}
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {assignedTo}
            </Typography>
          </Box>
          <Box style={webStyle.containerDataStyle}>
            <DateRangeIcon />
            <Box>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                }}
              >
                {dateRange}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  renderModal() {
    return (
      <Modal open={this.state.isAssignMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={{...webStyle.modalHeader, marginBottom: "8px"}}>
            <Typography style={webStyle.renameText}>
            Assign Member
             </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
          Select a user from the list below or search by name or email.
                    </Typography>
       
           <CustomSelect   memberError={this.state.memberError} onSelectionChange={(selectedUsers:any) => this.setState({ selectedUsers })} membersData={this.state.users}
           ref={(ref) => console.log("Props passed to CustomSelect:", this.state.users)}    selectedOptions={[]} navigation={undefined} id={""}/>
 
           <Box style={webStyle.modalButtons}>
            <RenameButton
              style={webStyle.cancelButton}
              onClick={() => this.handleAddMemberModal()}      
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
             onClick={this.handleAssignMember}
              data-test-id="removeMember-data-id"
              disabled={this.state.selectedUsers.length === 0} 
            >
              Assign
            </RenameButton>
          </Box>

        </Box>
      </Modal>
    );
  };

}

const CustomToast = styled(ToastContainer)({
  width: "unset",
  marginTop: "80px",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});

const webStyle = {
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  inviteMemberStyle:{
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
    margin: "0.5rem auto",
    paddingLeft:"12px"
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  statusStyle:{
    backgroundColor:"#3B82F6" ,
     fontWeight:400 ,
     fontSize:"12px",
     fontFamily:'Poppins',
     padding:"4px 6px 4px 8px",
     borderRadius:"40px",
     color:"#FFFFFF"

  },
  filterStyle:{
     borderRadius:"8px",
     backgroundColor:"white",
     BorderColor:"#CBD5E1",
     display:"flex",
     alignItems:"center",
     padding:"10px 12px",
     width:"7%"
  },
  containerDataStyle: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "#64748B",
  },
  priorityStyle: {
    borderRadius: "16px",
    width: "69px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "1px 8px",
  },
  roomNameStyle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#334155",
  },
  roomStyleCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  roomStyleMainCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  roomStatusCircleStyle: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
  },
  roomTypeStyle: {
    fontFamily: "Poppins",
    color: "#64748B",
    fontSize: "12px",
    fontWeight: 600,
  },
  cardOuterContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: "12px",
    margin:"1rem 0rem",
    width:"95%"
  },
  mainStatusStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusCountStyle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#237182",
    color: "white",
    textAlign: "center" as "center",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "poppins",
  },
  statusCircleStyle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#60A5FA",
    marginLeft:"1rem"
  },
  outerBoxStatus: {
    marginTop:"2rem",
    backgroundColor: "#FFFFFF",
    width: "95%",
    borderRadius: "8px",
    padding: "6px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    gap: "8px",
  },
  gridCont: {
     paddingLeft:"6.5rem"
  },

  headerSection2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "poppins",
    fontSize: "16px",
    marginTop:"1.5rem"
  },
  outerBox: {
    borderRadius: "5px",
    padding: "0px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.625rem 40px 0px 40px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "50px",
    padding: "4px",
  },

  PersonalInformationOption: {
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: "9px 16px",
    cursor: "pointer",
    fontSize: "16px",
  },

  BusinessInformationOption: {
    padding: "9px 16px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 &&
      ({
        textAlign: "center",
      } as React.CSSProperties)),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px 0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
  },

  sort: {
    marginRight: "3px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
};

// Customizable Area End
// Customizable Area End
