// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation: any;
  id: string;
  membersData: Member[];
  selectedOptions: [];
  onSelectionChange?: (selectedOptions: any[]) => void;
  memberError: string;
}

interface OptionType {
  value: number | string; // Allow string for added text
  label: string;
  email: string;
}

interface Member {
  id: number;
  attributes: {
    account: {
      first_name: string;
      last_name: string;
      email: string;
    };
  };
}

// Define the props for CustomSelect
interface CustomSelectProps {}

interface S {
  options: OptionType[];
  count: any;
  isMemberSelected: boolean;
  selectedMembers: any;
  inputValue: string;
}

interface SS {
  id: any;
}

export default class AddMembersModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      options: this.getOptions(props.membersData),
      count: null,
      isMemberSelected: false,
      selectedMembers: {},
      inputValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getOptions = (membersData: Member[]) => {
    return membersData.map((member) => {
      return {
        value: member.id,
        label: `${member?.attributes.account.first_name} ${member?.attributes.account.last_name}`,
        email: `${member?.attributes.account.email}`,
      };
    });
  };

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.membersData !== this.props.membersData) {
      this.setState({
        options: this.getOptions(this.props.membersData),
      });
    }
  }

  filterOption = (option: any, inputValue: string) => {
    const { label, email } = option.data;
    const input = inputValue.toLowerCase();
    this.setState({
      inputValue:input
    })

    return (
      label.toLowerCase().includes(input) || email.toLowerCase().includes(input)
    );
  };

  handleChange = (selectedOptions: any) => {
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(selectedOptions || []);
    }
  };

  onInputChange = (inputValue: string) => {
    const { options } = this.state;
    if (!inputValue) {
      this.setState({
        isMemberSelected: false,
      });
    }

    const filteredOptions = options.filter((option) => {
      const { label, email } = option;
      const input = inputValue.toLowerCase();
      return (
        label.toLowerCase().includes(input) ||
        email.toLowerCase().includes(input)
      );
    });

    this.setState({
      count: filteredOptions.length,
    });
  };

  handleSelectMember = (memberId: number) => {
    this.setState((prevState) => ({
      selectedMembers: {
        ...prevState.selectedMembers,
        [memberId]: !prevState.selectedMembers[memberId],
      },
    }));
  };

  handleAddAsText = () => {
    const { inputValue } = this.state;

    if (!inputValue.trim()) {
      return;
    }

    // Add the new option
    const newOption: OptionType = {
      value: inputValue,
      label: inputValue,
      email: "", // Optional or provide a placeholder email
    };

    // Update state
    this.setState((prevState) => ({
      options: [...prevState.options, newOption],
      inputValue: "", // Clear input
    }));
  };
}

// Customizable Area End
