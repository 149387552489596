export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backButton = require('../assets/imagebackicon.png');
export const rightIcone = require('../assets/right.png');
export const scalingIcon = require("../assets/scalingIcon.svg");
export const pdfIcon = require("../assets/pdfIcon.svg");
export const pdfFileIcon = require("../assets/pdfFileIcon.svg");
export const userIcon = require("../assets/userIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const cancelIcon = require("../assets/cancelIcon.svg");
export const pdfFileWarningIcon = require("../assets/pdfFileWarningIcon.svg");
export const warningIcon = require("../assets/warningIcon.svg");
