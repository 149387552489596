// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.sectionEndPoint  = "/bx_block_kanbanboard/sections"
exports.settingsEndPoint  = "/bx_block_kanbanboard/settings"
exports.GetTaskListEndPoint = "/bx_block_tasks/task_lists"
exports.validationApiMethodType = "GET"

// Customizable Area End
