// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Input,
  Select,
  Grid,
  MenuItem,
  styled,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  Collapse,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  Popper,
  PopperProps,
  Checkbox,
  ListItemText,
  Dialog,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon
} from "@mui/material";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Add,
  ArrowBackIos,
  Check,
  ControlPointDuplicateSharp,
  DeleteOutline,
  ExpandMore,
  Remove,
  Search,
} from "@material-ui/icons";
import CreateEditProjectPortfolioController, {
  Props,
  SearchRoomsDataItem
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { blackDownArrowIcon, calendarIcon, dollarIcon, downArrowIcon, frameIcon, rightArrowIcon, roundPlusIcon, threeDotIcon, upArrowIcon } from "./assets";
import Calendar from 'react-calendar';
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import * as Yup from "yup";
import { Formik } from "formik";
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/src/Loader.web";




const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});

const SortInput = styled(Select)({
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  marginLeft: "0.5rem",
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "2px",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#F0F5F7",
  },
  "& .MuiSelect-icon": {
    color: "#237182",
  },
});

const CustomStepper = styled(Stepper)({
  backgroundColor: "#F0F5F7",
  fontFamily: "Poppins, sans-serif",
  width: "100%",
  margin: "0 auto",
  padding: "0",
  "& .MuiStepConnector-line": {
    borderColor: "#94A3B8",
    borderTopWidth: "2px",
  },
  "& .MuiStepConnector-active .MuiStepConnector-line": {
    borderColor: "#0F172A",
  }
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#111827",
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  "& .css-4ff9q7.Mui-active": {
    color: '#111827',
    fontSize: '14px', fontWeight: 600,
    fontFamily: 'Poppins',
  },
  "& .css-2fdkz6.Mui-active": {
    marginLeft: '5px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  "& .css-d1rwnr .css-4ff9q7.Mui-active": {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  }
  ,

  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
  },
  "& .MuiStepIcon-text": {
    fontSize: '14px', fontWeight: 600, fontFamily: 'Poppins'
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 0,
  },
  "& .MuiTypography-root": {
    paddingLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#0F172A",
    fontWeight: 600,
  },
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: 'black',
  },
  "& .css-1hv8oq8-MuiStepLabel-label.Mui-active": {
    marginLeft: '5px',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
});

const BackButtonBox = styled(Box)({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'Poppins',
  "& p": {
    color: "#334155",
    fontWeight: 600,
    fontSize: '14px',
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
    color: "#334155",
  },
  "& .css-ahj2mt-MuiTypography-root": {
    fontFamily: 'Poppins',
  },
  "@media (max-width:1300px)":{
    width:'50%'
  },
  "@media (max-width:1060px)":{
    width:'20%'
  }
});

const Popup = styled(Menu)({

});

const StagesModal = styled(Menu)({
  '& .MuiPaper-root': {
    maxWidth: '322px',
    maxHeight: '430px',
    height:'auto',
    border: '1px solid rgba(203, 213, 225, 1)',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    marginTop: '3px',
    padding:'0px'
  },
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    margin:'16px',
    padding: '0px',
  },
});

const StagesUbModal = styled('div')({
  '&::-webkit-scrollbar': {
      width: '6px', 
    },
    '&::-webkit-scrollbar-track': {
      background: '#F1F5F9', 
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CBD5E1',
      borderRadius: '10px',
      border: '2px solid transparent', 
      backgroundClip: 'content-box',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#CBD5E1', 
    },

});


const SummaryDelete = styled(Menu)({
  '& .MuiPaper-root': {
    width: '192px',
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
  }, 
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    padding: '4px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px 0px 0px 12px',
    height: '36px',
  },
});
const specificOptions = ['Create your own',
  'Supply & Install Straight staircase',
  'Supply & Install Render to ceiling; Including base coat and finishing coat',
  'Supply & Install Render to wall; Including base coat and finishing coat',
  'Supply & Install Cut stringer straight staircase; Open stinger straight staircase',
  'Supply & Install Render to internal wall; Including base coat and finishing coat ',
  'Supply & Install Plaster to walls; Base coat and finish coat ',
  'Supply & Install Render to garden wall;  Including base coat and finishing coat ']

const TableField = styled(Table)({
  "& .css-177gid-MuiTableCell-root": {
    padding: '0px'
  }
});

const CalendarStyle = styled(Menu)({
  "& .react-calendar": {
    border: "none",
    fontFamily: "Urbanist",
    width: "348px",
    height: "352px"
  },
  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
    width: '348px',
    borderRadius: '8px',
    padding: '16px',
  }
  ,
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    width: '24px',
    height: '24px',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'black',
    fontSize: '14px',
    fontFamily: "Urbanist",
    fontWeight: 600,
    padding: '12px'
  },
  "& .react-calendar__navigation": {
    display: 'flex',
    gap: '10px'
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '30px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
    backgroundColor: 'transparent'
  },
  '& .react-calendar__month-view__weekdays': {
    padding: '8px'
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: 'none'
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#E0EDF0',
    color: '#325962',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'

  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '0 100% 100% 0'
  },
  "& .react-calendar__tile--active.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
  },
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .custom-calendar": {
    "& .react-calendar__tile--disabled": {
      backgroundColor: "#F1F5F9", 
      color: "#94A3B8",          
      pointerEvents: "none",      
      cursor: "not-allowed"
    },
    "& .react-calendar__tile--disabled:hover": {
      backgroundColor: "#F1F5F9"
    },
  },
  "& .custom-calendar .disabled-date": {
    color: "gray", 
    pointerEvents: "none",
    opacity:"0.5"
  }
});

const NextButton = styled(Button)({
  background: "#237182",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "none",
  width:'69px',
  height:'44px',
  "&:disabled": {
    background: "none",
    color: "#64748B",
  },
  "&:hover": {
    backgroundColor: "#237182",
  },
});

const AddTaskDialog = styled(Dialog)({
  '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    borderRadius: '16px',
    padding: '24px',
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginTop: '4px',
    color: '#94A3B8',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  },
  "& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: '8px'
  },
  "& .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    borderRadius: '8px'
  }

});

const MeasurementUnitField = styled(Box)({
  '& .MuiOutlinedInput-root': {
    paddingRight: '10px',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }
});


const MuiTableRow = styled(TableRow)({
  "& .MuiTable-root": {
    marginTop: "5px"
  }
});

const TaskListFilter = styled(Box)({
  width:'100%',
  display: 'flex', gap: '16px',
  "@media (max-width:1450px)":{
  width:'100%'
},
"@media (max-width:1360px)":{
 gap:'12px',
 width:'100%'
},
"@media (max-width:1290px)":{
 gap:'10px',
 width:'90%'
}
})

const DeleteDialog = styled(Dialog)({
  "& .css-11lq3yg-MuiGrid-root": {
    padding: '40px 32px 40px 32px'
  },
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    borderRadius: '16px'
  }
})

const MainAddNewRoomBox = styled(Box)({
  display:"flex",
  flexDirection:"column"
})

const AddNewRoomIconAndText = styled(Box)({
  display:"flex",
  alignItems:"flex-start",
  gap:"10px"
})

const SearchByRoomBox = styled(Box)({
  flexDirection:"column",
  backgroundColor:"#FFFFFF",
  position:"absolute",
  top:"300px",
  left:"150px",
  minWidth: "252px",
  borderRadius:"8px",
  padding:"8px",
  border:"1px solid #CBD5E1",
  boxShadow:"0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
})

const SearchByRoomTextfield = styled(TextField)({
  '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      borderColor:"#CBD5E1"
    },
})

const SelectedRoomsTextBox = styled(Box)({
display:"flex",
justifyContent:"flex-end",
margin:"5px 10px 0px 0px",
gap:"3px"
})

const SelectedRoomsText = styled("span")({
  fontFamily:"Poppins",
  fontWeight:400,
  fontSize:"12px",
  lineHeight:"16px",
  color:"rgba(100, 116, 139, 1)"
})

const SelectedRoomsCount = styled("span")({
  fontSize:"12px",
  lineHeight:"16px",
  color:"rgba(51, 65, 85, 1)",
  fontFamily:"Poppins",
  fontWeight:400,
})

const SearchByRoomCancelBtn = styled(Button)({
  border:"none",
  textTransform:"none",
  minWidth:"110px",
  borderRadius:"8px",
  padding:"6px 10px",
  backgroundColor:"rgba(224, 237, 240, 1)",
  fontFamily:"Poppins",
  fontSize:"16px",
  fontWeight:600,
  lineHeight:"24px",
  cursor:"pointer",
  color:"rgba(50, 89, 98, 1)",
  "&:hover": {
    border:"none",
    color:"rgba(255, 255, 255, 1)",
    backgroundColor:"rgba(35, 113, 130, 1)",
  },
})


const SearchByRoomAddEnabledBtn = styled(Button)({
  border:"none",
  textTransform:"none",
  fontFamily:"Poppins",
  fontSize:"16px",
  cursor:"pointer",
  fontWeight:600,
  lineHeight:"24px",
  color:"rgba(255, 255, 255, 1)",
  minWidth:"110px",
  borderRadius:"8px",
  padding:"6px 10px",
  backgroundColor:"rgba(35, 113, 130, 1)",
  "&:hover":{
    backgroundColor:"rgba(35, 113, 130, 1)",
    boxShadow:"none"
  }
})

const SearchByRoomAddBtn = styled(Button)({
  fontWeight:600,
  lineHeight:"24px",
  color:"rgba(148, 163, 184, 1)",
  minWidth:"110px",
  borderRadius:"8px",
  padding:"6px 10px",
  backgroundColor:"rgba(241, 245, 249, 1)",
  border:"none",
  textTransform:"none",
  fontFamily:"Poppins",
  fontSize:"16px",
  cursor:"pointer",
  "&:hover":{
    boxShadow:"none",
    backgroundColor:"rgba(241, 245, 249, 1)"
  }
})

const NumberInput = styled(Input)({
  input: {
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
      width:"60px",
      paddingBottom:"1px",
      fontFamily:"Poppins !important",
      fontWeight:400,
      fontSize:"16px",
      lineHeight:"24px"
    },
  },
})


export default class ProjectPortfolioShells extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    this.getShellsData = this.getShellsData.bind(this);
    this.getTaskList = this.getTaskList.bind(this)
    this.getBackBtnQuoteOverviewClick = this.getBackBtnQuoteOverviewClick.bind(this)

  }

  componentDidMount(): any {
    this.setState({ activeStep: 1 });
    this.getShellsData();
    this.getBackBtnQuoteOverviewClick()
  }

  addTaskSchema = () => {
    return Yup.object().shape({
      taskName: Yup.string().required("Task Name Required"),
      specifications: Yup.string().required("Specification Required"),
      Qty: Yup.number().typeError('Must be a number').required("Qty Required"),
      measurementUnit: Yup.string().required("Measurement Unit Required"),
      labourCostUnit: Yup.number().typeError('Must be a number').required("Labour Cost Unit Required"),
      materialCostUnit: Yup.number().typeError('Must be a number').required("Material Cost Unit Required"),
    });
  };

  CustomPopper = (props: JSX.IntrinsicAttributes & PopperProps & React.RefAttributes<HTMLDivElement>) => {
    return (
      <Popper
        {...props}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
        style={{
          zIndex: 1300,
          scrollbarColor: "#CBD5E1 #F1F5F9",
          border: '1px solid #CBD5E1',
          backgroundColor: '#FFFFFF',
          width: '586px',
          maxHeight: '376px',
          marginTop: '10px',
          borderRadius: '8px',
          fontWeight: 400,
          fontSize: '14px',
          color: '#3C3E49',
          fontFamily: 'Poppins',
          overflowY: 'scroll',
        }}
      />
    );
  };

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2rem",
      height: "2rem",
      fontFamily: "Poppins, sans-serif",
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };


  taskSubjects = (open3: boolean) => (
    this.state.taskList.map((data: any, index: number) => (
      <>
        <TableRow key={index}>
          <TableCell align="left">
            <Box style={webStyle.tasklistData} >
              {data.Room}
              <span
                data-test-id="right-Btn1"
                onClick={() => this.setState({ tableElT: !this.state.tableElT, tableIndex: index})}
              >
                <img src={this.handleCondition(
                  this.state.tableElT && this.state.tableIndex === index,
                  blackDownArrowIcon,
                  rightArrowIcon
                )} width={'28px'} height={'28px'} alt="" />
              </span>
            </Box>
          </TableCell>
          <TableCell align="center" sx={webStyle.tasklistDate}>
            <p style={webStyle.date} >
              <div  onClick={this.handleCalendarOpen} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <span>
                <img data-test-id="start-date" src={calendarIcon} style={{ width: '20px', height: '20px',marginRight:"5px" }} alt="start"
                />
              </span>
              <span >{this.formatDate(this.state.startDateOpen) || this.formatDate(new Date())}</span>
              </div>
              <CalendarStyle
                anchorEl={this.state.anchorElt3}
                open={open3}
                onClose={this.handleCalendarClose}
              >
                <p
                  style={{ fontWeight: 600, fontSize: '16px', margin: '0px', textAlign: 'center', fontFamily: 'Poppins', color: '#0F172A' }}>
                  Select Start & End date</p>
                <Calendar selectRange data-test-id="dateSelect" onChange={(selectItem)=>this.handleDateChange(selectItem)} value={this.state.tempDate}     
                  tileDisabled={this.isTileDisabled}
                  tileClassName={this.getTileClassName}
                  className="custom-calendar"
                  />
              </CalendarStyle>
            </p>
          </TableCell>
          <TableCell align="center" sx={webStyle.tasklistDate} onClick={this.handleCalendarOpen}>
          {this.formatDate(this.state.endDateOpen) || this.formatDate(new Date())}
          </TableCell>
          <TableCell style={{ padding: '14px',fontFamily:'Poppins' }} align="center">
            {data.TotalEarn}
          </TableCell>
          <TableCell>
            <span
              data-test-id="more-Btn"
              onClick={this.handleTaskListPopup}><img src={threeDotIcon} width={'28px'} height={'28px'} alt="" />
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          {this.taskLists(data, index)}
        </TableRow>
      </>
    ))
  )
  taskLists = (data: any, index: number) => (

    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
      <Collapse in={this.state.tableElT && this.state.tableIndex === index} timeout="auto" unmountOnExit>
        <Box    >
          {data.row.history.map((historyRow: any, histryIdx: number) => {
            return (
              <>
                <Box key={historyRow.date + 1} style={{ display: 'flex', justifyContent: 'space-between',padding:'16px 0px 16px 32px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', cursor: 'pointer', margin: 0 }}>
                    {historyRow.date}
                    <span
                      data-test-id={`right-Btn2${histryIdx}`}
                      onClick={() => this.setState({
                        subTaskActive: !this.state.subTaskActive,
                        subTaskId: histryIdx
                      })
                      }
                    >
                      <img src={this.handleCondition(this.state.subTaskActive && this.state.subTaskId === histryIdx, blackDownArrowIcon, rightArrowIcon)}
                       width={'28px'} height={'28px'}
                        alt="" />
                    </span>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', cursor: 'pointer', margin: 0, alignContent: 'center' }}>
                    {historyRow.amount}
                    <span
                      data-test-id="more-Btn"
                      onClick={this.handleTaskListPopup}>
                         <img 
                         src={threeDotIcon}
                       width={'28px'} height={'28px'}
                        alt="" />
                       </span>
                  </Box>
                </Box>
                {this.taskSpecifications(historyRow, histryIdx)}
              </>
            )
          })}
          <Box style={webStyle.taskListAddTask}>
            <img src={roundPlusIcon} width={'20px'} height={'20px'} alt="add member" />
            Add TaskList <ExpandMoreIcon style={{ color: '#237182' }} /> </Box>
        </Box>
      </Collapse>
    </TableCell>
  )

  taskSpecifications = (historyRow: any, histryIdx: number) => (
    <Collapse in={this.state.subTaskActive && this.state.subTaskId === histryIdx} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Table size="small" aria-label="purchases" >
          <TableHead>
            <TableRow style={{ backgroundColor: '#D3E4E9', color: '#334155', fontWeight: 600, fontSize: '12px',borderRadius:'8px' }}>
              <TableCell style={webStyle.subTaskHeading} >Task</TableCell>
              <TableCell style={webStyle.subTaskHeading} >Specifications</TableCell>
              <TableCell align="center"
                style={{ color: '#334155', fontWeight: 600, fontSize: '12px', fontFamily: 'Poppins', width: '90px' }} >
                Qty
              </TableCell>
              <TableCell align="center" style={webStyle.THead}>Measurement unit</TableCell>
              <TableCell align="center" style={webStyle.THead}>Labour cost / unit</TableCell>
              <TableCell align="center" style={webStyle.THead}>Materials cost / unit</TableCell>
              <TableCell align="center" style={webStyle.THead}>Unit cost</TableCell>
              <TableCell align="center" style={webStyle.THead}>Total Cost</TableCell>
              <TableCell align="center" style={webStyle.THead}>Client Supplied</TableCell>
            </TableRow>
          </TableHead>
          <TableBody >

            {historyRow.subTask.map((subData: any, id: number) => (
              <MuiTableRow key={id + 1} style={{ backgroundColor: '#F0F5F7', fontWeight: 400, fontSize: '14px' }}>
                <TableCell component="th" scope="row" width={'306px'}>
                  <SpecificationDropDown2 >
                    <Autocomplete
                      id="search-task"
                      data-test-id={`specification-select-${id}`}
                      noOptionsText="No Records Found"
                      value={subData.Task}
                      options={specificOptions}
                      style={{ backgroundColor: '#FFFFFF', width: '324px' }}
                      className="scrollbar-color"
                      PopperComponent={this.CustomPopper}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select or search task"
                          fullWidth
                          onFocus={() => this.setState({ inputFocus: !this.state.inputFocus, getSpeciIndex1: id })}
                          onBlur={this.handleBlur4}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.handleCondition(this.state.inputFocus && this.state.getSpeciIndex1 === id, <ExpandLessIcon />, <ExpandMoreIcon />)}
                              </InputAdornment>
                            )

                          }}
                        />

                      )}
                      renderOption={(props: any, option: string) => {
                        if (option === 'Create your own') {
                          return <MenuItem {...props}
                            onClick={() => this.setState({ openAddTask: true ,taskListId:subData.id})}
                            style={{'&:hover': { backgroundColor: '#E0EDF0',}}}
                                >
                            <p style={{ fontSize: '14px', fontWeight: 600, color: '#237182', fontFamily: 'Poppins', padding: 0, margin: 0 }} >
                              Create your own </p>
                          </MenuItem> }

                        return (
                         <MenuItem {...props} style={{
                            '&:hover': { backgroundColor: '#E0EDF0', color: '#0F172A', },
                          }}
                        >
                  <ListItemText primary={option} />
                        </MenuItem>) }}/>
                  </SpecificationDropDown2>
                </TableCell>
                <TableCell width={'306px'} >
                  <SpecificationDropDown2 >
                    <Autocomplete
                      id="search-specification"
                      data-test-id={`specification-select2-${id}`}
                      noOptionsText="No Records Found"
                      value={subData.Specifications}
                      options={specificOptions}
                      PopperComponent={this.CustomPopper}
                      style={{ backgroundColor: '#FFFFFF', width: '324px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select specifications"
                          fullWidth
                          onFocus={() => this.setState({ inputFocus2: !this.state.inputFocus2, getSpeciIndex2: id })}
                          onBlur={this.handleBlur}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.handleCondition(this.state.inputFocus2 && this.state.getSpeciIndex2 === id, <ExpandLessIcon />, <ExpandMoreIcon />)}
                              </InputAdornment>
                            )

                          }}
                        />

                      )}
                      renderOption={(props: any, option: string) => ( <MenuItem {...props} style={{
                            '&:hover': {
                              backgroundColor: '#E0EDF0',
                              color: '#0F172A',
                            },
                          }} >
                          <Checkbox checked={this.state.selectedOptions.includes(option)}  onChange={() => this.handleCheckboxChange(option)} value={option} color="primary"/>
                          <ListItemText primary={option} />
                        </MenuItem>)}/> </SpecificationDropDown2>
                </TableCell>
                <TableCell align="center">

                  <Box style={webStyle.Qty}>
                    {subData.Qty}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  {subData.Measurementunit}
                </TableCell>
                <TableCell align="center">
                  {subData.Labourcostunit}
                </TableCell>
                <TableCell align="center">
                  {subData.Materialscostunit}
                </TableCell>
                <TableCell align="center">
                  {subData.Unitcost}
                </TableCell>
                <TableCell align="center">
                  {subData.TotalCost}
                </TableCell>
                <TableCell align="center">
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', alignItems: 'center', cursor: 'pointer', margin: 0, alignContent: 'center' }}>
                    <input type="checkbox"
                      style={{
                        width: '20px', height: '20px', borderRadius: '8px', border: '1px solid #64748B',
                        backgroundColor: '#F0F5F7'
                      }}
                    />
                    <span
                      data-test-id="more-Btn"
                      onClick={this.handleTaskListPopup}>
                        <img src={threeDotIcon} width={'28px'} height={'28px'} alt="" />
                        </span>
                  </Box>
                </TableCell>
              </MuiTableRow>
            ))}
            {this.state.specificationActive && this.editFiledSpecification()}
          </TableBody>
        </Table>
        {this.addTaskPopup()}
        <Box
          style={webStyle.specificationAddTask}
          onClick={this.handleSpecificationOpen}
        >
          <img src={roundPlusIcon} width={'20px'} height={'20px'} alt="add member" />
          Add Task  </Box>
      </Box>
    </Collapse>
  )

  editFiledSpecification = () => (
    <>
      <TableRow
        style={{ backgroundColor: '#F0F5F7', fontWeight: 400, fontSize: '14px' }}>
        <TableCell width={'306px'} component="th" scope="row">
          <SpecificationDropDown >
            <Autocomplete
              id="search-task"
              data-test-id="specification45"
              noOptionsText="No Records Found"
              value={this.state.specific1}
              options={specificOptions}
              onChange={this.handleSpeciChange}
              style={{ backgroundColor: '#FFFFFF', width: '324px' }}
              className="scrollbar-color"
              PopperComponent={this.CustomPopper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select or search task"
                  fullWidth
                  onFocus={this.handleFocus1}
                  onBlur={this.handleBlur1}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: this.handleCondition(this.state.specific1 === '', (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#94A3B8' }} />
                      </InputAdornment>
                    ), ""),
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">  {this.handleCondition(!this.state.inputFocus, <ExpandMoreIcon />, <ExpandLessIcon />)}
                      </InputAdornment>)}}/>)}
              renderOption={(props: any, option: string) => { if (option === 'Create your own') { return <MenuItem
                    {...props} onClick={this.handletaskClick} style={{
                      '&:hover': {
                        backgroundColor: '#E0EDF0',
                      },
                    }}>
                    <p style={{ fontSize: '14px',
                       fontWeight: 600,
                        color: '#237182', 
                        fontFamily: 'Poppins', 
                        padding: 0,
                         margin: 0 }} >
                      Create your own </p>
                  </MenuItem>  }

                return (<MenuItem
                  {...props}
                  style={{
                    '&:hover': { backgroundColor: '#E0EDF0', color: '#0F172A',}, }} >
  <ListItemText primary={option} /></MenuItem>) } } />
          </SpecificationDropDown>
        </TableCell>
        <TableCell width={'306px'} component="th" scope="row">
          <SpecificationDropDown >
            <Autocomplete
              id="search-specification"
              data-testId="specification-select41"
              noOptionsText="No Records Found"
              value={this.state.specific2}
              options={specificOptions}
              onChange={this.handleSpeciChange2}
              PopperComponent={this.CustomPopper}
              style={{ backgroundColor: '#FFFFFF', width: '324px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select specifications"
                  fullWidth
                  onFocus={this.handleFocus2}
                  onBlur={this.handelBlur2}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: this.state.specific2 === '' && (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#94A3B8' }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.handleCondition(!this.state.inputFocus2, <ExpandMoreIcon /> ,<ExpandLessIcon />)}
                      </InputAdornment>
                    )

                  }}
                />

              )}
              renderOption={(props: any, option: string) => (
                <MenuItem
                  {...props}
                  style={{
                    '&:hover': {
                      backgroundColor: '#E0EDF0',
                      color: '#0F172A',
                    },
                  }}
                >
                  <Checkbox
                    checked={this.state.selectedOptions.includes(option)} 
                    onChange={() => this.handleCheckboxChange(option)} 
                    value={option}
                    color="primary"
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              )}
            />
          </SpecificationDropDown>
        </TableCell>
        <TableCell align="center">

          <Box style={webStyle.Qty}>
            Qty
          </Box>
        </TableCell>
        <Measurementunit align="center">
          <Autocomplete
            id="search-specification"
            data-testId="Unit"
            noOptionsText="No Records Found"
            value={this.state.measurementUnit}
            options={["Unit 1", "Unit 2"]}
            onChange={this.handleSpeciChange3}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Unit"
                onFocus={this.handleFocus3}
                onBlur={this.handleBlur3}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {this.handleCondition(!this.state.inputFocus3, <ExpandMoreIcon />, <ExpandLessIcon />)}
                    </InputAdornment>
                  )

                }}
              />

            )}
          />
        </Measurementunit>
        <TableCell align="center">

        </TableCell>
        <TableCell align="center">

        </TableCell>
        <TableCell align="center">

        </TableCell>
        <TableCell align="center">

        </TableCell>
        <TableCell align="center">
          <Box
            onClick={this.handleSpecificationClose}
            sx={{ display: 'flex', width: '100%', justifyContent: 'end', alignItems: 'center', cursor: 'pointer', margin: 0, alignContent: 'center' }}>
            <ClearIcon style={{ color: '#475569', width: '20px', height: '20px' }} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  )


  addTaskPopup = () => (
    <AddTaskDialog data-test-id={'add-task-popup'} onClose={() => this.setState({ openAddTask: false })} open={this.state.openAddTask}>

      <Formik
      data-test-id={'formik'}
        initialValues={{
          taskName: "",
          specifications: "",
          Qty: "",
          measurementUnit: "",
          labourCostUnit: "",
          materialCostUnit: "",
        }}
        validationSchema={this.addTaskSchema}
        onSubmit={(values, { resetForm }) => {
          this.createOwnTask(values)
          this.setState({ openAddTask: false })
          resetForm()
        }}
      >
        {({ errors, touched, handleChange, handleBlur, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "24px", fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins' }}>
                  Add Task
                </span>
                <CloseIcon
                  style={{ cursor: "pointer", width: '20px', height: '20px' }}
                  onClick={this.handleAddClick}
                />
              </Grid>

              
              <Grid item xs={12}>
                <label htmlFor="Task" style={webStyle.addTaskPopupHeadings}>Task :</label>
                <TextField
                  fullWidth
                  id="taskName"
                  data-test-id={"taskName"}
                  name="taskName"
                  placeholder="Enter task here"
                  value={values.taskName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taskName && Boolean(errors.taskName)}
                  helperText={touched.taskName && errors.taskName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="Specifications" style={webStyle.addTaskPopupHeadings}>Specifications :</label>
                <TextField
                  fullWidth
                  id="specifications"
                  data-test-id={"specifications"}
                  name="specifications"
                  placeholder="Enter specifications"
                  value={values.specifications}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.specifications && Boolean(errors.specifications)}
                  helperText={touched.specifications && errors.specifications}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="Qty" style={webStyle.addTaskPopupHeadings}>Qty:</label>
                <TextField
                  fullWidth
                  id="Qty"
                  name="Qty"
                  data-test-id={"Qty"}
                  placeholder="Enter Qty"
                  value={values.Qty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Qty && Boolean(errors.Qty)}
                  helperText={touched.Qty && errors.Qty}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="MeasurementUnit" style={webStyle.addTaskPopupHeadings}>Measurement Unit:</label>
                <MeasurementUnitField>
                  <FormControl fullWidth error={touched.measurementUnit && Boolean(errors.measurementUnit)}>
                    <Select
                      id="measurementUnit"
                      name="measurementUnit"
                      data-test-id={"Qty"}
                      fullWidth
                      value={values.measurementUnit}
                      onChange={handleChange}
                      onOpen={this.handleOpenCLick}
                      onClose={this.handleCloseClick}
                      displayEmpty
                      IconComponent={()=>this.handleCondition(this.state.inputFocus4, <ExpandLessIcon />, <ExpandMoreIcon />)}
                      renderValue={(selected) => this.handleCondition(selected, selected , "Measurement Unit")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      }}
                    >
                      <MenuItem value="Unit 1">Unit 1</MenuItem>
                      <MenuItem value="Unit 2">Unit 2</MenuItem>
                    </Select>
                    {touched.measurementUnit && errors.measurementUnit && (
                      <FormHelperText>{errors.measurementUnit}</FormHelperText>
                    )}
                  </FormControl>
                </MeasurementUnitField>

              </Grid>
            <Grid item xs={6}>
                <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>Labour Cost / Unit:</label>
                <TextField
                  fullWidth
                  id="labourCostUnit"
                  data-test-id={"labourCostUnit"}
                  name="labourCostUnit"
                  placeholder="Enter Cost"
                  value={values.labourCostUnit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.labourCostUnit && Boolean(errors.labourCostUnit)}
                  helperText={touched.labourCostUnit && errors.labourCostUnit}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ color: "#64748B", fontWeight: 600 }}>£</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <label htmlFor="MaterialCostUnit" style={webStyle.addTaskPopupHeadings}>Material Cost / Unit:</label>
                <TextField
                  fullWidth
                  data-test-id={"materialCostUnit"}
                  id="materialCostUnit"
                  name="materialCostUnit"
                  placeholder="Enter Cost"
                  value={values.materialCostUnit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ borderRadius: '8px' }}
                  error={touched.materialCostUnit && Boolean(errors.materialCostUnit)}
                  helperText={touched.materialCostUnit && errors.materialCostUnit}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ color: "#64748B", fontWeight: 600 }}>£</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "#E0EDF0",
                    color: "#325962",
                    fontWeight: 600,
                    width: "90px",
                    height: "56px",
                    borderRadius: "8px",
                    border: "none",
                  }}
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{
                    backgroundColor: this.handleCondition(Object.values(values).every(
                      (value) => value !== undefined && value !== null && value !== ""
                    ), '#237182', "#F1F5F9"),
                    color: this.handleCondition(Object.values(values).every(
                      (value) => value !== undefined && value !== null && value !== ""
                    ), '#FFFFFF', "#64748B"),
                    fontWeight: 600,
                    width: "90px",
                    height: "56px",
                    borderRadius: "8px",
                    border: "none",
                  }}
                >
                  Next
                </button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </AddTaskDialog>
  )

   shellsAndRoomsImg = (activeStep:number,logo:string)=>{
    return <img src={activeStep === 1 ? baseURL + logo : logo} width={'80px'} height={'80px'} style={{ borderRadius: '50%' }} />
  }

  shellsAndRoomsCardBorder = (amount:number)=>{
    return amount > 0 ? webStyle.gridItemWithBorder : webStyle.gridItem
  }





  render() {
    const toggle = this.state.toggle
    const { anchorEl, anchorElt3 } = this.state;
    const open = Boolean(anchorEl);
    const open3 = Boolean(anchorElt3)
        return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loader} />
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <Box bgcolor="#F0F5F7" px="2.5rem" py="2.5rem" minHeight={'82vh'} width={'94vw'}  >
            <Box style={webStyle.pageTitle}>
              {this.handleCondition(
                this.state.activeStep < 6, <BackButtonBox style={{width:this.state.activeStep > 3 ? "22%" : "100%"}} data-test-id="backBtn1" onClick={()=>this.handleShellsBack()}>
                <ArrowBackIos />
                <Typography sx={webStyle.backText}>Back</Typography>
              </BackButtonBox>,
                null
              )}
              {this.state.activeStep > 3 && <TaskListFilter >
                    <button data-test-id="popupBtn" onClick={(event) => this.handlePopup(event, 'button1')} style={webStyle.tabButtons}>Stages <img src={this.handleCondition(this.state.buttonId === 'button1' && this.state.activeButton, upArrowIcon, downArrowIcon)} width='24px' height='24px' alt="stages" /></button>
                    <button data-test-id="popupBtn1" onClick={(event) => this.handlePopup(event, 'button2')} style={webStyle.tabButtons}>Rooms <img src={this.handleCondition(this.state.buttonId === 'button2' && this.state.activeButton, upArrowIcon, downArrowIcon)} width='24px' height='24px' alt="rooms" /></button>
                    <button data-test-id="popupBtn2" onClick={(event) => this.handlePopup(event, 'button3')} style={{...webStyle.tabButtons,width:'115px'}}>
                      <img src={dollarIcon} width='24px' height='24px' alt="rates" />
                      Rates
                      <img src={this.handleCondition(this.state.buttonId === 'button3' && this.state.activeButton, upArrowIcon, downArrowIcon)} width='24px' height='24px' alt="Rates" />
                    </button>
                  <StagesModal
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={open}
                    elevation={0}
                    onClose={this.handleClose}
                    style={{ minWidth: '322px', borderRadius: '8px' }}
                  >
                    <StagesUbModal style={{maxHeight: '334px',overflowY: 'auto'}}>
                    {this.state.stages.map((item: string, index: number) => {
                      return (
                        <MenuItem data-test-id={`menuItems1${index}`} value={this.state.checkValue} onChange={(event: any) => this.setState({ checkValue: event.target.value })} key={item + 1}

                          style={{
                            padding: '12px 16px 12px 0px',maxWidth: '290px', display: 'flex', gap: '8px', alignItems: 'center', fontWeight: 400, fontSize: '14px',
                            backgroundColor: this.handleCondition(this.state.buttonId === 'button3' && index === 1, "#E0EDF0", '')
                          }}
                          onClick={() => this.handleCondition(this.state.buttonId === 'button3', this.handleClose(), '')}
                        >
                          {this.handleCondition(this.state.buttonId !== 'button3', <input type="checkbox" value={item}
                            style={{ width: '20', height: '20px', borderRadius: '6px', border: '1px solid #64748B' }} />, '')}
                          <span style={{fontFamily:'Poppins',fontSize:'14px',fontWeight:400,color:'#0F172A',whiteSpace: 'normal'}}>{item}</span>
                        </MenuItem>
                      )
                    })}
                    </StagesUbModal>
                    {this.handleCondition(this.state.buttonId !== 'button3',
                      <Box style={{ display: 'flex', gap: '16px', padding:'16px 16px 0px 16px' }}>
                        <Button onClick={this.handleClose} variant="contained" style={{...webStyle.clearAll,textTransform: 'none'}}>
                          Clear All</Button>
                        <Button onClick={this.handleClose} variant="contained" style={{...webStyle.apply,textTransform: 'none'}}>
                          Apply
                        </Button>
                      </Box>, '')}
                  </StagesModal>
                </TaskListFilter>}
              <CustomStepper nonLinear activeStep={this.state.activeStep}>
                {this.getSteps().map((placeholder, index) => (
                  <Step
                    key={placeholder}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <ActiveStepLabel
                      StepIconComponent={(props) => (
                        <this.CustomStepIcon
                          {...props}
                          active={this.state.activeStep === index}
                          completed={this.state.activeStep > index}
                          style={{ fontSize: '1.5rem', fontWeight: 600, fontFamily: 'Poppins' }}
                        />
                      )}
                    >
                      <Typography style={webStyle.stepperText}>
                        {this.state.activeStep === index && placeholder}
                      </Typography>
                    </ActiveStepLabel>
                  </Step>
                ))}
              </CustomStepper>
              <Box style={webStyle.buttonsBlock}>
                <Box style={webStyle.addMemberBtn}>
                  <Typography style={webStyle.addMemberText}>
                    View & Add Member
                  </Typography>
                </Box>
                <Button style={webStyle.saveDraftBtn}>Save Draft</Button>
                <NextButton
                  disabled={
                    !this.state.enableNextBtn ?  !this.state.shellsSubstructures.some(
                      (item) => item.amount > 0
                    ) : false
                  }
                  data-test-id="next-btn"
                  onClick={this.handleNext}
                >
                  Next
                </NextButton>
              </Box>
            </Box>

            <Box style={webStyle.topBox}>
              {this.handleCondition(
                this.state.activeStep === 3, <Box component="div" style={ToggleButtonStyle.OptionsBox}>
                <Box component="div" 
                style={{ ...ToggleButtonStyle.WDH, border:this.handleCondition(toggle, "1px solid #D3E4E9", "transparent"), color: this.handleCondition(toggle, "#325962", "#64748B"), backgroundColor: this.handleCondition(toggle, "#E0EDF0", "transparent") } as React.CSSProperties} data-test-id="Width-Depth-Height" onClick={() => this.handleChangePage(true)}>W / D / H</Box>
                <Box component="div" style={{ ...ToggleButtonStyle.AreaParameter,border:this.handleCondition(!toggle, "1px solid #D3E4E9", "transparent"), color: this.handleCondition(!toggle, "#325962", "#64748B"), backgroundColor: this.handleCondition(!toggle, "#E0EDF0", "") } as React.CSSProperties} data-test-id="AreaParameter" onClick={() => this.handleChangePage(false)}>Area / Perimeter</Box>
              </Box>
                ,
                <InputContainer>
                  <Input
                    startAdornment={<Search style={webStyle.searchIcon} />}
                    style={webStyle.searchInput}
                    placeholder="Search here..."
                    onChange={(e) => this.handleSearchInputChange(e.target.value)}
                    value={this.state.searchInputText}
                    disableUnderline
                    data-test-id="search-input"
                  />
                </InputContainer>
              )}
              {
                this.handleCondition(
                  this.state.activeStep < 3,
                  <Box style={webStyle.topBox}>
                    <Typography style={webStyle.sortText}>Sort by:</Typography>
                    <SortInput
                      disableUnderline
                      IconComponent={ExpandMore}
                      defaultValue={1}
                      sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    >
                      <MenuItem value={1}>Alphabetical</MenuItem>
                    </SortInput>
                  </Box>,
                  this.handleCondition(
                    !this.state.toggle && this.state.activeStep === 3,
                    <Box style={{ display: 'flex',
                     gap: '24px', 
                      width:'467px',
                      height:'44px' }}>
                      <Box
                        style={{ position: 'relative',fontSize: '16px',
                          fontWeight: 400, 
                          color: '#334155',display:"flex", alignItems:"center" }}
                      >
                       <span style={{color: '#334155', fontSize: '16px', fontWeight: 600, fontFamily: 'Poppins',marginRight:"8px"}}>Area: </span> 
                        <input type="text" placeholder="" disabled style={webStyle.areaInput} value={this.state.totalArea.toFixed(2)} />
                        <p style={{ position: 'absolute', 
                          right: '5px',
                           top: '9px',
                            margin: 0,
                            fontWeight:600,
                            fontSize:'16px',color:'#64748B',fontFamily: 'Poppins'}}>m²</p>
                      </Box>
                      <Box style={{ position: 'relative',fontSize: '16px',
                          fontWeight: 600, 
                          color: '#334155',fontFamily: 'Poppins',display:"flex", alignItems:"center",gap:"7px" }}>Perimeter:  <input type="text" placeholder="" value={this.state.totalPerimeter.toFixed(2)} disabled style={webStyle.areaInput} />
                        <p style={{ position: 'absolute', 
                          right: '5px',
                           top: '9px',
                            margin: 0,
                            fontWeight:600,
                            fontSize:'16px',color:'#64748B',fontFamily: 'Poppins'}}>m</p>
                      </Box>
                    </Box>,
                    ''
                  )
                )
              }
            </Box>

            <Box style={{ width: '100%' }}>
              {this.handleCondition(
                this.state.activeStep >= 3, <Box style={{width: '100%' }}>
                <TableContainer style={{ backgroundColor: '#FFFFFF', width: '100%', marginTop: '15px',borderRadius:'8px' }}  >
                  <TableField sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        style={{ fontSize: '16px', fontWeight: 600 }}>
                        <TableCell style={{ display: 'flex', gap: '7px', alignItems: 'center', fontSize: '14px', fontWeight: 600, fontFamily: 'Poppins', color: '#475569' }}>
                          
                          <MainAddNewRoomBox>
                            <AddNewRoomIconAndText>
                          Room/Area
                          <img src={roundPlusIcon} style={{marginBottom:"2px"}} width={'20px'} height={'20px'} alt="add member" />
                          <span
                            data-test-id="toggle-add-new-room"
                            onClick={this.toggleAddNewRoomPopup}
                            style={{ color: '#237182', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 600, fontFamily: 'Poppins',cursor:"pointer" }}>
                            Add Rooms
                            {!this.state.addNewTaskPopup ? <ExpandMoreIcon style={{ color: '#237182',paddingLeft:"6px"}} /> : <ExpandLessIcon style={{ paddingLeft:"6px",color: '#237182',}}/>}
                          </span>
                          </AddNewRoomIconAndText>
                          <SearchByRoomBox style={{display:this.state.addNewTaskPopup ? "flex" : "none"}}>
                          <Box padding={1}>
                          <SearchByRoomTextfield
                            data-test-id='searchChangeNew'
                            variant="outlined"
                            placeholder="Search by room..."
                            value={this.state.searchByRoomText}
                            fullWidth
                            onChange={(e)=>this.handleSearchByRoomText(e.target?.value)}
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon style={{color:"rgba(148, 163, 184, 1)"}}/>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>


                        {this.state.selectedAddRoomPopup.length > 0 &&<SelectedRoomsTextBox>
                            <SelectedRoomsText>Selected Room:</SelectedRoomsText>
                            <SelectedRoomsCount>{this.state.selectedAddRoomPopup.length}</SelectedRoomsCount>
                        </SelectedRoomsTextBox>}

                        <Box style={{ maxHeight: '180px', overflowY: 'auto', marginTop:'2px' }}>
                          <List>
                            {this.state.searchRoomsData.map((room,index)=>{
                              return <ListItem
                              data-test-id="handleCheckboxToggle"
                              key={`ListItem_${index}`}
                              style={{padding:"7px 16px"}}
                              onClick={()=>this.handleCheckedRooms(room.id,room.checked)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  checked={room.checked}
                                  style={{
                                    padding: 0,
                                    borderRadius: '6px',
                                    width: '20px',
                                    height: '20px',
                                    pointerEvents: 'auto',
                                    cursor:"pointer"
                                  }}
                                  icon={
                                    <span
                                      style={{
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '6px',
                                        width: '20px',
                                        height: '20px',
                                        border: '1px solid #64748B',
                                        paddingBottom:"1px",
                                        display: 'inline-block',
                                        cursor:"pointer"
                                      }}
                                    />
                                  }
                                  checkedIcon={
                                    <span
                                      style={{
                                        backgroundColor: '#325962',
                                        color: '#fff',
                                        borderRadius: '6px',
                                        width: '20px',
                                        height: '20px',
                                        display: 'inline-block',
                                        border: '1px solid #325962',
                                        paddingBottom:"1px"
                                      }}
                                    >
                                      <Check style={{ color: 'white', fontSize: 18 }} />
                                    </span>
                                  }
                                  data-test-id="handleToggleItem"
                                />
                              </ListItemIcon>
                              <ListItemText primary={
                              <Typography style={{
                                color:'#0F172A',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                marginLeft:'-24px',
                                cursor:"pointer",
                                paddingTop:"1px"
                              }}>{room.name}</Typography>
                                } />
                            </ListItem>
                            })}
                              
                          </List>
                        </Box>

                        <Box display="flex" style={{display:"flex",justifyContent:"space-between"}} padding={1}>
                          <SearchByRoomCancelBtn
                            data-test-id="handleCancel"
                            onClick={this.addNewRoomCancelBtn}
                            color="secondary"
                            variant="outlined"
                          >
                            Cancel
                          </SearchByRoomCancelBtn>
                          {this.state.selectedAddRoomPopup.length > 0 ? <SearchByRoomAddEnabledBtn
                            data-test-id="handle-add-room-btn"
                            color="primary"
                            variant="contained"
                            onClick={this.addNewRoomAddBtn}
                          >
                            Add
                          </SearchByRoomAddEnabledBtn> : <SearchByRoomAddBtn
                            data-test-id="handleAdd2"
                            color="primary"
                            variant="contained"
                            disabled
                          >
                            Add
                          </SearchByRoomAddBtn>}
                        </Box>

                          </SearchByRoomBox>
                          </MainAddNewRoomBox>
                        </TableCell>
                        {this.handleCondition(
                          this.state.activeStep < 4,
                          this.handleCondition(
                            this.state.toggle, (<>
                              <TableCell align="right" style={webStyle.tableHead} >Width</TableCell>
                              <TableCell align="right" style={webStyle.tableHead}>Depth</TableCell>
                              <TableCell align="right" style={webStyle.tableHead}>Height</TableCell>
                              <TableCell style={{ width: '0px' }}></TableCell>
                            </>)

                            ,

                            (<>
                              <TableCell align="right" style={webStyle.tableHead}> Area (m²)</TableCell>
                              <TableCell align="right" style={webStyle.tableHead}>Perimeter (m)</TableCell>
                              <TableCell style={{ width: '0px' }}></TableCell>
                            </>)
                          )
                          ,
                          (<>
                            <TableCell align="center" style={webStyle.tableHead}>Start Date</TableCell>
                            <TableCell align="center" style={webStyle.tableHead}>End Date</TableCell>
                            <TableCell align="center" style={webStyle.tableHead}> 3,589,177.82</TableCell>
                            <TableCell style={{ width: '0px' }}></TableCell>
                          </>
                          )
                        )
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.handleCondition(
                        this.state.activeStep < 4,
                        this.handleCondition(
                          this.state.toggle, (<>
                            {this.state.searchRoomsData?.map((room: SearchRoomsDataItem, index: number) => (
                             
                              <TableRow
                                key={index}
                                style={{ fontSize: '16px', fontWeight: 400 ,}} >
                                <TableCell align="left" style={{fontFamily:'Poppins',fontSize:'16px',color:'#0F172A',fontWeight:400}}>{room.name}</TableCell>
                                <TableCell align="right" style={{fontFamily:'Poppins',fontSize:'16px',padding:'7px',color:'#0F172A',fontWeight:400}}>
                                  <span style={{  fontFamily:'Poppins',fontWeight:400,fontSize:"16px",lineHeight:"24px",color: '#0F172A' }}>
                                  <NumberInput type="number"
                                  value={room.width}
                                  
                                  onInput={(e:React.FormEvent<HTMLInputElement>)=>this.handleOnInput(e)}
                                  inputProps={{
                                    style: {
                                      appearance: "textfield",
                                    },
                                  }}
                                  onChange={(e)=>this.handleMeasurementFields(room.id,"width",e.target.value)}
                                   />
                                  </span>
                                  <span style={{  fontFamily:'Poppins',fontWeight:600,fontSize:"16px",lineHeight:"24px",paddingLeft:"8px",color: '#64748B', }}>
                                    m
                                  </span>
                                  </TableCell>
                                <TableCell align="right" style={{fontFamily:'Poppins',fontSize:'16px',padding:'7px',color:'#0F172A',fontWeight:400}}>
                                  <span style={{  fontFamily:'Poppins',fontWeight:400,fontSize:"16px",lineHeight:"24px",color: '#0F172A' }}>
                                <NumberInput type="number"
                                data-test-id="number-input-2"
                                 inputProps={{
                                  style: {
                                    appearance: "textfield",
                                  },
                                }}
                                  value={room.depth}
                                  onInput={(e:React.FormEvent<HTMLInputElement>)=>this.handleOnInput(e)}
                                  onChange={(e)=>this.handleMeasurementFields(room.id,"depth",e.target.value)}
                                   />
                                  </span>
                                  <span style={{  fontFamily:'Poppins',fontWeight:600,fontSize:"16px",lineHeight:"24px",paddingLeft:"8px",color: '#64748B', }}>
                                    m
                                  </span>
                                  </TableCell>
                                <TableCell style={{fontFamily:'Poppins',fontSize:'16px',padding:'7px',color:'#0F172A',fontWeight:400}}align="right">
                                    <span style={{  fontFamily:'Poppins',fontWeight:400,fontSize:"16px",lineHeight:"24px",color: '#0F172A' }}>
                                 <NumberInput type="number"
                                  value={room.height}
                                  onInput={(e:React.FormEvent<HTMLInputElement>)=>this.handleOnInput(e)}
                                  inputProps={{
                                    style: {
                                      appearance: "textfield",
                                    },
                                  }}
                                  
                                  onChange={(e)=>this.handleMeasurementFields(room.id,"height",e.target.value)}
                                   />
                                  </span>
                                  <span style={{  fontFamily:'Poppins',fontWeight:600,fontSize:"16px",lineHeight:"24px",paddingLeft:"8px",color: '#64748B', }}>
                                   m
                                  </span>
                                </TableCell>
                                <TableCell>
            <span
               data-test-id={`more-Btn1${index}`}
               onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handlePopup(event, 'button4')}><img src={threeDotIcon} width={'28px'} height={'28px'} alt="" />
            </span>
          </TableCell>
                                <SummaryDelete
                                  anchorEl={this.state.anchorEl}
                                  id={`fade-menu-${index}`}
                                  keepMounted
                                  open={open}
                                  elevation={0}
                                  onClose={this.handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  <MenuItem onClick={this.handleClose} style={{padding:'0px 0px 0px 12px',height:'36px',color:'#0F172A',fontSize:'14px',fontWeight:400,fontFamily:'Poppins'}}>
                                    <ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
                                    Duplicate
                                  </MenuItem>
                                  <MenuItem onClick={this.handleClose} style={{ color: '#DC2626',padding:'0px 0px 0px 12px',height:'36px',fontSize:'14px',fontWeight:400,fontFamily:'Poppins'}}>
                                    <DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
                                    Delete
                                  </MenuItem>
                                </SummaryDelete>
                              </TableRow>))}
                          </>),
                          (<>
                            {this.state.searchRoomsData?.map((room: SearchRoomsDataItem, index: number) => (
                              <TableRow
                                key={index}
                                style={{ fontSize: '16px', fontWeight: 400 }} >
                                <TableCell align="left" style={{fontFamily:'Poppins',fontSize:'16px',color:'#0F172A',fontWeight:400}}>{room.name}</TableCell>
                                <TableCell align="right" style={{fontFamily:'Poppins',fontSize:'16px',color:'#0F172A',fontWeight:400}}>
                                  <span style={{  fontFamily:'Poppins',fontWeight:400,fontSize:"16px",lineHeight:"24px",color: '#0F172A' }}>
                                  {room.area}
                                  </span>
                                  <span style={{  fontFamily:'Poppins',fontWeight:600,fontSize:"16px",lineHeight:"24px",paddingLeft:"8px",color: '#64748B', }}>
                                    m&sup2;
                                  </span>
                                  </TableCell>
                                   <TableCell style={{ padding: '10px',fontFamily:'Poppins',color:'#0F172A',fontWeight:400 }} align="right">
                                   <span style={{ color: '#0F172A', fontFamily:'Poppins',fontWeight:400,fontSize:"16px",lineHeight:"24px" }}>
                                   {room.perimeter}
                                  </span>
                                  <span style={{ color: '#64748B', fontFamily:'Poppins',fontWeight:600,fontSize:"16px",lineHeight:"24px",paddingLeft:"8px" }}>
                                    m
                                  </span>
                                </TableCell>
                                <TableCell>
            <span
               data-test-id={`more-Btn${index}`}
               onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.handlePopup(event, 'button5')}><img src={threeDotIcon} width={'28px'} height={'28px'} alt="" />
            </span>
          </TableCell>

                                <SummaryDelete
                                  anchorEl={this.state.anchorEl}  id={`fade-menu-${index}`} keepMounted
                                  open={open} elevation={0}  onClose={this.handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',horizontal: 'right',}}
                                  transformOrigin={{  vertical: 'top', horizontal: 'right', }} >
                                  <MenuItem onClick={this.handleClose} style={{color:'#0F172A',fontSize:'14px',fontWeight:400,fontFamily:'Poppins'}}><ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />Duplicate</MenuItem>
                                  <MenuItem onClick={this.handleClose} style={{ color: '#DC2626',fontSize:'14px',fontWeight:400,fontFamily:'Poppins' }}><DeleteOutline style={{ height: '20px', marginRight: '4px' }} />Delete</MenuItem>
                                </SummaryDelete>
                              </TableRow>))}
                          </>)
                        ),
                        (<>
                          {this.taskSubjects(open3)}
                        </>)
                      )}
                    </TableBody>
                    <SummaryDelete
                      anchorEl={this.state.anchorElT}
                      keepMounted
                      open={this.state.anchorElT}
                      elevation={0}
                      onClose={this.handleClose2}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={this.handleClose2} style={{color:'#0F172A',fontSize:'14px',fontWeight:400,fontFamily:'Poppins'}}>
                        <ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
                        Duplicate
                      </MenuItem>
                      <MenuItem onClick={this.handleClose2} style={{ color: '#DC2626',fontSize:'14px',fontWeight:400,fontFamily:'Poppins' }}>
                        <DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
                        Delete
                      </MenuItem>
                    </SummaryDelete>
                  </TableField>
                </TableContainer>
              </Box>,
                <Grid container spacing={2} xs={12} style={webStyle.itemsGrid}>
                  {this.state.filteredItems.map((item, index: number) => (
                    <Grid
                      item
                      md={3}
                      sm={6}
                      xs={12}
                      style={{ marginTop: "1.5rem" }}
                      key={item.id}
                    >
                      <Box style={this.shellsAndRoomsCardBorder(item.amount)}>
                        {this.shellsAndRoomsImg(this.state.activeStep,item.logo)}
                        <Typography style={{
                          margin: "1rem", fontWeight: 600, fontSize: '14px', lineHeight: '22px',
                          fontFamily: "Poppins", color: '#475569'
                        }}>
                          {item.name}
                        </Typography>
                        <Box style={webStyle.counterBox}>
                          <Remove
                            style={webStyle.counterButton}
                            onClick={() =>
                              this.handleAmountChange(item.id, "remove")
                            }
                            data-test-id={`remove-btn${index}`}
                          />
                          <Typography style={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Poppins',color:'#1E293B' }}>{item.amount}</Typography>
                          <Add
                            style={webStyle.counterButton}
                            onClick={() => this.handleAmountChange(item.id, "add")}
                            data-test-id={`add-btn${index}`}
                          />
                        </Box>
                      </Box>
                    </Grid>))}
                  {this.state.filteredItems.length === 0 &&
                    <Box style={{ width: '100vw', height: '68vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                      <img src={frameIcon} alt="" width='457.46px' height='350px' />
                      <Box style={webStyle.chatGroupNotFound}>
                        <p style={webStyle.noMessage}>
                          No Results Found
                        </p>
                        <p
                          style={webStyle.noMessageSub}
                        >
                          We couldn't find that you searched for.  Try searching again.
                        </p>

                      </Box>
                    </Box>}
                </Grid>
              )

              }
            </Box>

          </Box>
          <DeleteDialog open={false}>
            <Grid container >
              <Grid item xs={11}>
                <Typography style={webStyle.deleteText}>
                  Are you sure you want to delete this room?
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <CloseIcon style={{ width: '20px', height: '20px', color: '#0F172A', marginTop: '7px', cursor: 'pointer' }} />
              </Grid>
              <Grid item xs={12}>
                <Typography style={webStyle.actionTxt}>
                  This action cannot be undone.
                </Typography>
              </Grid>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '16px', paddingTop: '32px' }}>
                <button
                  style={webStyle.cancelBtn}
                >
                  Cancel
                </button>
                <button
                  style={webStyle.deleteBtn}
                >
                  Delete
                </button>
              </Box>
            </Grid>
          </DeleteDialog>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  totalExpense:
  {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 0,
    alignContent: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#0F172A'
  },
  tableHead: { width: '150px', fontSize: '14px', fontWeight: 600, color: '#475569', fontFamily: 'Poppins' },
  tasklistDate: { fontSize: '14px', fontWeight: 400, color: '#0F172A', fontFamily: 'Poppins' },
  tasklistData: {
    fontSize: '16px', fontWeight: 400, color: '#0F172A', fontFamily: 'Poppins',
    display: 'flex', width: '100%', justifyContent: 'start', alignItems: 'center',
    cursor: 'pointer', margin: 0, alignContent: 'center',gap: "8px"
  },
  cancelBtn: {
    width: '90px', height: '56px', backgroundColor: '#E0EDF0', color: '#325962',
    fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px'
  },
  deleteBtn: {
    width: '90px', height: '56px', backgroundColor: '#237182', color: '#FFFFFF',
    fontWeight: 600, fontSize: '16px', fontFamily: 'Poppins', borderRadius: '8px'
  },
  deleteText: { fontSize: '24px', fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins' },
  actionTxt: { fontSize: '16px', fontWeight: 400, color: '#1E293B', fontFamily: 'Poppins' },
  countText: { fontFamily: 'Poppins' },
  backText: { color: '#334155', fontSize: '14px', fontWeight: 600, fontFamily: 'Poppins' },
  stepperText: { fontFamily: 'Poppins', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px' },
  pageTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  buttonsBlock: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width:'100%',
    justifyContent:'end',
  },
  addMemberBtn: {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
  },
  addMemberIcon: {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  addMemberText: {
    fontWeight: 600,
    color: "#237182",
    fontSize: '15px',
    lineHeight: '24px',
    fontFamily: 'Poppins'
  },
  saveDraftBtn: {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: 'Poppins',
    textTransform: "none" as "none",
  },
  topBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchInput: {
    border: "1px solid #D3E4E9",
    background: "#FCFDFE",
    borderRadius: "8px",
    width: '392px',
    height: '32px',
    padding: '10px 16px 10px 16px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#94A3B8'
  },
  searchIcon: {
    color: "#DADADA",
    width: "20px",
    height: "20px",
    marginRight: "0.5rem",
  },
  sortText: {
    color: "#334155",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400, fontFamily: 'Poppins'
  },
  gridItem: {
    background: "white",
    borderRadius: "8px",
    textAlign: "center" as "center",
    padding: "1.75rem 0",
  },
  gridItemWithBorder: {
    background: "white",
    borderRadius: "8px",
    textAlign: "center" as "center",
    padding: "1.75rem 0",
    border:"1px solid rgba(35, 113, 130, 1)"
  },
  counterBox: {
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center",
    gap: "1rem",
  },
  counterButton: {
    width: "18px",
    height: "18px",
    color: "#0F172A",
    cursor: "pointer",
  },
  itemsGrid: {
    margin: "0px",
    marginTop: '10px',
  },
  areaInput: {
    width: '150px',
    height: '44px',
    outline: 'none',
    borderRadius:'6px',
    border:'1px solid #CBD5E1',
    fontWeight:400,
    fontSize:'16px',
    padding:'10px'
  },
  tabButtons: {
    display: "flex",
    alignItems: "center",
    padding: "10px 8px 10px 8px",
    backgroundColor: " white",
    borderRadius: '8px',
    fontWeight: 400,
    fontSize: '16px',
    border: '1px solid #e0e5e9',
    width: '96px',
    height: '44px',
    justifyContent: 'space-between',
  },
  clearAll: {
    width: '137px',
    height: '32px',
    borderRadius: '8px',
    fontWeight: 600, fontSize: "16px",
    padding: '6px 10px 6px 10px',
    backgroundColor: '#E0EDF0',
    color: '#325962',
    
    
  },
  apply: {
    width: '137px',
    height: '32px',
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: "16px",
    padding: '6px 10px 6px 10px',
    backgroundColor: '#237182',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
  },
  date: {
    gap: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    coursor: 'pointer',
  },
  Qty: {
    width: '90px',
    borderRadius: '8px',
    padding: '10px 8px 10px 8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #CBD5E1',
    display: 'flex',
    alignItems: 'center'
    , justifyContent: 'center',
    color: '#0F172A',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  THead: {
    fontWeight: 600,
    fontSize: '12px',
    padding: '8px',
    fontFamily: 'Poppins',
    color: '#334155',
    width: '90px'
  },
  projectChatSideScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatGroupNotFound: {
    width: '#1E293B',
    height: '76px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '8px'
  },
  noMessage: {
    textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    margin: 0,
    padding: 0,
    color: '#1E293B',
    width: '457.46px'
  },
  noMessageSub: {
    color: '#475569',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    margin: 0,
    padding: 0,
    textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
    width: '457.46px'

  },
  subTaskName: {
    width: '306px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    color: '#0F172A',
    backgroundColor: '#FFFFFF',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px 10px 8px',
    justifyContent: 'space-between'
  },
  subTaskName2: {
    width: '306px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    color: '#0F172A',
    backgroundColor: '#FFFFFF',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px 10px 8px',
    gap: '8px'
  }
  ,
  subTaskHeading: {
    color: '#334155',
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: 'Poppins',
    paddingLeft: '22px'
  },
  specificationAddTask:
  {
    display: 'flex',
    gap: '7px',
    paddingLeft: '16px',
    paddingTop: '10px',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    color: '#237182',
    fontFamily: 'Poppins'
  },
  taskListAddTask:
  {
    display: 'flex',
    gap: '7px',
    padding: '12px 0px',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
    color: '#237182',
    fontFamily: 'Poppins'
  },
  addTaskEditField: {
    borderRadius: '16px',
    padding: '24px',
    backgroundColor: '#FFFFFF'
  },
  addTaskPopupHeadings: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#475569',
  }
};

const InputContainer = styled("div")(({ theme }) => ({
  '& input::placeholder': {
    color: '##A6B0BF'
  }
}));

const SpecificationDropDown = styled(Box)({
  width: '100%',
  "& .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root": {
    width: '324px',
    borderRadius: '8px',
    color: '#94A3B8',
    backgroundColor: '#FFFFFF',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    padding: '3px 8px'
  },
  "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root": {
    padding: '3px 8px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }

});

const SpecificationDropDown2 = styled(Box)({
  width: '100%',
  "& .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root": {
    width: '324px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    padding: '3px 8px'
  },
  "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root": {
    padding: '3px 8px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }

});

const Measurementunit = styled(TableCell)({
  "& .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: '8px',
    color: '#94A3B8',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    width: '95px'
  },
  "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root": {
    padding: '3px 8px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Poppins',
    width: '95px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  },
  '& .css-ka7ti6-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    minWidth: '42px'
  }

});

const ToggleButtonStyle = {
  PersonalInformationBox: {
    padding: "24px 48px 24px 48px", fontFamily: "Poppins",
    marginLeft: "6rem"
  },
  WDH: {
    padding: "16px 18px 16px 18px", borderRadius: "50px",
    fontFamily: "Poppins", fontWeight: 600,
    cursor: "pointer", ...(window.innerWidth < 600 && {
      textAlign: "center"
    }),
  },
  AreaParameter: {
    padding: "12px 18px 12px 18px", borderRadius: "50px",
    fontFamily: "Poppins", fontWeight: 600,
    cursor: "pointer", lineHeight: "27px",
    margin: "0px", ...(window.innerWidth < 600 && {
      textAlign: "center"
    })
  },
  OptionBox: {
    display: "flex", justifyContent: "start",
  },
  OptionsBox: {
    display: "flex", backgroundColor: "#F8FAFC",
    borderRadius: "50px", padding: "4px"
  },
  personInfoDetailBox: {
    display: "flex", gap: "24px"
  },
  PersonalInformationDetailBox: {
    marginTop: "24px", padding: "30px 32px 30px 32px",
    backgroundColor: "white", borderRadius: "16px"
  }
}

// Customizable Area End