import React from "react";
import Select, { components } from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import {
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import { Box, styled, Typography,Button } from "@material-ui/core";
import AddMembersModalController from "./AddMembersModalController";
import { checkedIcon, unCheckIcon } from "./assets";


class CustomSelect extends AddMembersModalController {
  CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          padding: "0 10px",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          color: "#333",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          transition: "background-color 0.3s ease",
          border: "none",
        }}
      >
        <Box style={{ overflowY: "auto", width: "100%" }}>
          <List style={{ padding: "0px" }}>
            <ListItem
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
              }}
            >
              <ListItemAvatar style={{ display: "flex", gap: "8px" }}>
                <Checkbox
                  data-test-id="tasklist-change-id"
                  checked={!!this.state.selectedMembers[data.value]} // Check based on member ID
                  onChange={() => this.handleSelectMember(data.value)} // Pass the member ID
                  icon={
                    <img src={unCheckIcon} style={{ width: 16, height: 16 }} />
                  }
                  checkedIcon={
                    <img src={checkedIcon} style={{ width: 16, height: 16 }} />
                  }
                  style={{ color: "#64748B" }}
                />

                <Avatar
                  style={{
                    background: "#D3E4E9",
                    color: "#ABCDD5",
                    marginRight: "8px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: 700,
                      paddingTop: "4px",
                    }}
                  >
                    {data.label.slice(0, 2).toUpperCase()}
                  </span>
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        fontFamily: "poppins",
                        color: "#000",
                        lineHeight: "24px",
                      }}
                    >
                      {data.label}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        fontFamily: "poppins",
                        color: "#64748B",
                        lineHeight: "22px",
                      }}
                    >
                      {data.email}
                    </Typography>
                  </Typography>
                }
              />
              {/* <IconButton
                style={{ color: "#000", opacity: 1 }}
              >
                <AddIcon />
              </IconButton> */}
            </ListItem>
          </List>
        </Box>
      </div>
    );
  };

  CustomNoOptionsMessage = (props: any) => {
    const { inputValue } = this.state;
    return (
      <components.NoOptionsMessage {...props}>
        <Box
          padding="1rem"
        >
          <Typography
            variant="body1"
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#64748B",
            }}
          >
            No matches found. <span style={{fontWeight:600 , color:"#1E293B"}}>Add 'Sandra' as text?</span>
          </Typography>
          {inputValue && (
            <Box style={{display:"flex",alignItems:"center",width:"100%",justifyContent:"space-between"}}>   
              <Box style={{color:"#0F172A" ,fontWeight:600 , fontSize:"16px",fontFamily:"poppins"}}>{inputValue}</Box>   
          <RenameButton
            style={{
              backgroundColor: "#E0EDF0",
              color:"#325962",
            }}
            onClick={this.handleAddAsText}
          >
            Add as text
          </RenameButton></Box>

          )}
        </Box>
      </components.NoOptionsMessage>
    );
  };

  CustomMenuList = (props: any) => {
    return <CustomScroll>{props.children}</CustomScroll>;
  };

  render() {
    const { options } = this.state;
    return (
      <>
        <Box
          mt="1.5rem"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <style>
            {`
            .basic-multi-select {
              width: 100%;
              position: unset;
            }
          `}
          </style>
          <CustomWrapper>
            <Box style={{ position: "relative" }}>
              <SearchIcon
                style={{
                  position: "absolute",
                  left: "1rem",
                  top: "28px",
                  transform: "translateY(-50%)",
                  color: "grey",
                  zIndex: 100,
                }}
              />
              <Select
                options={options}
                isMulti
                styles={{
                  control: (provided, state) => {
                    const borderColor = this.props.memberError
                      ? "1px solid #DC2626"
                      : "1px solid #ccc";
                    return {
                      ...provided,
                      display: "flex",
                      flexWrap: "wrap",
                      padding: state.hasValue ? "0.3rem" : "0.9rem",
                      border: borderColor,
                      borderRadius: "8px",
                      paddingLeft: "2.5rem",
                      boxShadow: "none",
                      "&:hover": {
                        border: borderColor,
                      },
                    };
                  },
                  menu: (provided) => ({
                    ...provided,
                    boxShadow: "none",
                    borderRadius: "8px",
                    maxHeight: "395px",
                    overflow: "auto",
                    position: "relative",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "26px",
                    background: "#E0EDF0",
                    padding: "6px 12px",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#325962",
                    marginLeft: "10px",
                    fontFamily: "Poppins",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    fontSize: "14px",
                    color: "#325962",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    color: "#325962",
                    fontWeight: 400,
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#325962",
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: 0,
                    padding: "0 6px",
                    width: "auto",
                    flexGrow: 1,
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#0F172A",
                    lineHeight: "24px",
                    fontFamily: "Poppins",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontFamily: "Poppins",
                    paddingLeft: "6px",
                  }),
                  noOptionsMessage: (provided) => ({
                    ...provided,
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 400,
                    textAlign: "center",
                  }),
                }}
                menuIsOpen
                hideSelectedOptions={false}
                className="basic-multi-select"
                classNamePrefix="select"
                getOptionLabel={(e) => e.label}
                onChange={this.handleChange}
                onInputChange={this.onInputChange}
                // noOptionsMessage={this.noOptionsMessage}
                filterOption={this.filterOption}
                components={{
                  Option: this.CustomOption,
                  MenuList: this.CustomMenuList,
                  DropdownIndicator: () => null,
                  NoOptionsMessage: this.CustomNoOptionsMessage,
                }}
                placeholder="Enter email address or search for a user..."
                data-test-id="select-id"
              />
            </Box>
            {this.props.memberError && (
              <Box
                mt="0.5rem"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "0.75rem",
                  color: "#DC2626",
                  lineHeight: "1rem",
                  position: "absolute",
                  top: "3.5rem",
                  zIndex: 10,
                }}
                data-test-id="error-message"
              >
                {this.props.memberError}
              </Box>
            )}
          </CustomWrapper>
        </Box>
      </>
    );
  }
}

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  fontSize: "16px",
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const CustomWrapper = styled(Box)({
  " & .css-xn6sei-menu": {
    maxHeight: "unset",
  },
});

const CustomScroll = styled(Box)({
  marginTop: "0.6rem",
  backgroundColor: "white",
  padding: "0px",
  maxHeight: "386px",
  boxShadow: "none",
  scrollbarWidth: "auto",
  overflowY: "scroll",
  overflowX: "hidden",
  scrollbarColor: " #CBD5E1 #F1F5F9",

  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },
});

export default CustomSelect;
