import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Client as ConversationsClient } from "@twilio/conversations";
import { getStorageData } from "framework/src/Utilities";



// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    statusString: string;
    status: string;
    conversationsClient: ConversationsClient | null; // Allow null as initial value
    conversationList: any[]; 
  dummyArray:any[],

    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ChatConversationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createTwilioTokenApiCallId:string = "";
    createChatRoomApiCallId: string = "";
    conversationsClient: any;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            statusString: "",
            status: "",
            conversationsClient: null,
            conversationList: [],
            dummyArray:[1,2],


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getTwilioTokenApi();
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (errorResponse) {
        this.parseApiCatchErrorResponse(errorResponse);
      }
      if (responseJson?.errors) {
        this.parseApiErrorResponse(responseJson);
      }
      if (this.createTwilioTokenApiCallId === apiRequestCallId) {
        console.log("1111111 ",responseJson.token);
        this.initChat(responseJson.token)
       }
  }
    sendMessage=()=>{
        console.log("msg");
        
      }
      getTwilioTokenApi = async () => {
        const token = await getStorageData("token")
        const header = {
          "Content-Type": "application/json",
          token,
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createTwilioTokenApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_chat/chat_channels/twilio_token`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      initChat = async (token: string) => {
        try {
          
          this.setState({ statusString: "Connecting to Twilio&" });
          this.conversationsClient = new ConversationsClient(token); //initilizing twilio
          this.conversationsClient.on("connectionStateChanged", (state:string) => {
    
            if (state == "connected") {
              // this.createConversationApi()  //BE api to get conversation/channel id
              this.setState({
                statusString: "You are connected.",
                status: "success"
              });
            }
    
          }); 
    
       await this.conversationsClient.on("conversationJoined", (conversation: any) => {
            return conversation
          });
    
        }catch(error){
          runEngine.debugLog(error)
        }
      }

     
    // Customizable Area End
}
