import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");
interface ProjectData {
  id:string;
  projectName:string,
  fileCount:string,
  name:string,
}

interface Meta {
  message: string;
}

interface DrawingDataList {
  data: ProjectData[];
  meta: Meta;
}

interface File {
  name:string;
  size:string;
}

// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  DrawingDataList : DrawingDataList;
  toggle:string;
  visibleCount: number,
  token:string,
  loading:boolean,
  openUploadDialog:boolean,
  searchQuery: string;
  file: File | null;
  errorMessage:string | null;
  fileSizeError:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
export default class DrawingScalingDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetProjectsListCallId: string | null = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   DrawingDataList:{
     data: [],
     meta: {
       message: ""
     }
   },
   toggle:'Draft',
   visibleCount: 8,
   token:'',
   loading:false,
   openUploadDialog:false,
   searchQuery: '',
   file: null,
   errorMessage: null,
   fileSizeError:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleScroll = this.handleScroll.bind(this);
    this.receive = this.receive.bind(this);
    // Customizable Area End
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getProjectsListData(token);
    }
    // Customizable Area End
  

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiGetProjectsListCallId) {
        this.getProjectListResponse(responseJson);
      }

    
    }
    
  }
  handleToggle=(dataName:any)=>{
    this.setState({toggle:dataName},()=>this.getProjectsListData(this.state.token))
   
  }
  loadMoreCards = () => {
    this.setState((prevState: any) => ({
      visibleCount: prevState.visibleCount + 4,
    }));
  };
  handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;
    if (bottom) {
      this.loadMoreCards();
    }
  };
  getProjectsListData = async (token:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const selectedTabState = this.state.toggle
    const searchValue = this.state.searchQuery
    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllProjectsAPIEndPoint}?status=${selectedTabState}&search=${searchValue}&project_plan[]=Specialist cost plan&project_plan[]=Automated cost plan`
   
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProjectListResponse=(responseJson:DrawingDataList)=>{
   if(responseJson?.data){
    this.setState({
      DrawingDataList: {
        ...this.state.DrawingDataList, 
        data: responseJson.data,  
        meta : responseJson.meta 
      },
    });
    
  }else{
    this.setState({
      DrawingDataList: {
        ...this.state.DrawingDataList, 
        data: [], 
        meta : responseJson.meta   
       
      },
    })
  }

  }
  handleButtonScaling=()=>{
    this.setState({
      openUploadDialog:true
    }) 
  }
  handleCloseUploadDialog=()=>{
    this.setState({
      openUploadDialog:false
    })  
  }
  handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ searchQuery: query },()=>this.getProjectsListData(this.state.token));
  };
 
  handleRemoveUploadedFile=()=>{
    this.setState({file:null})
  }
  handleUploadFile=()=>{
    console.warn(this.state.file)
   
  }
 

 

  
  // Customizable Area End
}
