Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Drawing";
exports.labelBodyText = "Drawing Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiContentType = "application/json";  
exports.getAllProjectsAPIEndPoint = "bx_block_profile/projects";
exports.getAPiEndMethod = "GET"
// Customizable Area End