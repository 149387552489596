import React from "react";
import "gantt-task-react/dist/index.css";

import {
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { Gantt } from "gantt-task-react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import GanttChartController, {
  configJSON,
  Props,
} from "./GanttChartController";

export default class GanttChart extends GanttChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <ThemeProvider theme={theme}>
            <div style={webStyle.mainBox}>
              <div style={webStyle.ganttBoxWrapper}>
                <Gantt
                  tasks={this.state.ganttChartTasks}
                  viewMode={this.state.ganttChartView}
                  listCellWidth={this.state.isChecked ? "155px" : ""}
                  columnWidth={this.state.ganttChartColumnWidth}
                />
              </div>
            </div>
        </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    padding: "20px",
  },
  viewSwitcherWrapper: {
    marginTop: "10px"
  },
  ganttBoxWrapper: {
    marginTop: "20px"
  },
  buttonStyle: {
    marginLeft: "10px",
    border: "none",
    backgroundColor: "#F0F0F0",
  },
  chkboxWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }
};
// Customizable Area End