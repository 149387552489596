Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelTitleText = "Bulk Upload";
exports.uploadBtnLabel = "Upload";
exports.clearFileBtnLabel = "Clear files";
exports.submitBtnLabel = "Submit";
exports.getUploadedFileLabel = "Get All Uploaded File";
exports.idLabel = "ID:";
exports.statusLabel = "Status: ";
exports.deleteBtnLabel = "Delete";
exports.removeBtnLabel = "Remove";
exports.downloadBtnLabel = "Download";
exports.apiContentType = "application/json"
exports.createContentType = "multipart/form-data"
exports.createBulkUploadEndpoint = "bulk_uploading/attachments";
exports.createBulkUploadMethod = "POST";
exports.uploadingSuccessMsg = "File uploaded Successfully!"
exports.getBulkUploadEndpoint = "bulk_uploading/attachments";
exports.getBulkUploadMethod = "GET";
exports.deleteBulkUploadSingleEndpoint = "bulk_uploading/attachments/delete_attachment";
exports.deleteBulkUploadEndpoint = "bulk_uploading/attachments";
exports.deleteBulkUploadMethod = "DELETE";
exports.deletedSuccessMsg = "Attachment file deleted successfully!";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 5MB!";
exports.downloadedSuccessMsg = "file has been downloaded! File Path: ";
exports.downloadingFailedMsg = "Something went wrong!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.loginAlertMessage = "Please login first.";
exports.uploadPdfBtn = 'Upload Pdf';
exports.uploadFileText = 'Upload File';
exports.attachedFilesText = 'Attached Files:';
exports.fileTypeText = 'File Type:';
exports.electrical = 'Electrical';
exports.heating = 'Heating';
exports.plumbing = 'Plumbing';
exports.cancelBtn = 'Cancel';
exports.uploadBtn = 'Upload';
exports.pdfSupported = 'Only PDF files are supported';
exports.pdfFileSizeError = 'File size is too long. Maximum size is 5 MB';
exports.dragDropText = 'Drag & Drop the files here or';
exports.acceptableFormat = 'Acceptable format: PDF';
exports.maxSize = '(Max size: 10MB)';


// Customizable Area End