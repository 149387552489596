import React from "react";

// Customizable Area Start
import {
    Box,
    Input,
    Typography,
    Button
} from "@mui/material";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    styled,
} from "@mui/material/styles";

import ChatConversationController, { Props } from "./ChatConversationController";
import {profile,} from "./assets";
import AttachFileIcon from '@mui/icons-material/AttachFile';


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class ChatConversation extends ChatConversationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    InputSendMSG = ()=>{
        return(
          <Box style={{ padding:"11px 20px", display:"flex", position:"relative", background:"white"}}>
        
             <CustomInput
                endAdornment={<AttachFileIcon  style={webStyles.attachment_icon} />}
                style={webStyles.sendmsgInput}
                placeholder="Type a message here..."
                disableUnderline
                data-test-id="Type a message here..."
                />
                 <Button
                  data-test-id="sendBtn-id"
                  onClick={this.sendMessage.bind(this)}
                  style={{
                    background: "#F1F5F9",
                    color: "#64748B",
                    fontFamily:'Poppins',
                    fontWeight:600,
                    fontSize:'16px',
                    lineHeight:'24px',
                    marginLeft:"15px",
                    padding:"10px 16px",
                    textTransform: "capitalize",
                  }}
               
                >
                  Send
                </Button>
          </Box>
    
        )
    
      }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledEngineProvider injectFirst>

                <ThemeProvider theme={theme}>
                <MainchatMsg >
<Box>
  <Box className="today_date">

  <Box className="date_line" >  </Box>
  <Typography className="date">
  16 May, 2024
    </Typography>
  </Box>



{
this.state.dummyArray.map((item)=>(
 <Box style={{padding:"20px 0px",maxWidth:"800px"}} key={item}>
   <Box  style={{display:"flex", alignItems: "center" ,justifyContent: "space-between"}}>
     <Box style={{display:"flex", alignItems: "center"}}>
      <img src={profile} alt="" width={'32px'} height={'32px'} style={{borderRadius: "6px", marginRight:"10px" }}   />
       <Typography className="rece-user">Tom Wilson</Typography>
        <Typography className="rece-msg_time">2:30 PM</Typography>
     </Box>

  </Box>
   <Typography className="receive_msg">Morning, team. Quick updates on Cambridge Riverside. Emma, design updates?</Typography>
</Box>
        ))
      }
      </Box>
</MainchatMsg>
{ this.InputSendMSG() }

                </ThemeProvider>
            </StyledEngineProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const MainchatMsg = styled(Box)({
    height: "calc(100vh - 238px)",
    overflowY: "scroll", 
    background:"#F1F5F9",
    padding:"0 20px",
        overflowX: "hidden", 
        "& .today_date":{
          zIndex: 1,
          position: 'relative',
        },
        "& .date_line":{
          background: '#CBD5E1',
          textAlign: 'center',
          height: '2px',
        width: '100%',
        marginTop:"15px",
        },
        "& .date":{
          background: '#F1F5F9',
          position: 'absolute',
          left: 0,
          right: 0,
          top: '-19px',
          width: 'fit-content',
          margin: 'auto',
          padding: '10px',
          zIndex: 9999,
          fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
        },
    "& .receive_msg":{
      paddingLeft:"44px",
      maxWidth: "600px",
      fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color:"#0F172A"
      
    },
    "& .rece-user":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color:"#0F172A",
    },
    "& .rece-msg_time":{
      fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color:"#94A3B8",
    paddingLeft:"6px"
    },
      scrollbarWidth: "auto", 
      scrollbarColor:" #CBD5E1 #F1F5F9", 
    
       "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#CBD5E1",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F1F5F9",
      },
    
    
    
    });
    const CustomInput = styled(Input)({
        padding:" 0px 0px 0px 5px",
      "& .MuiInputBase-input::placeholder": {
        fontSize:'16px',
    color: "#94A3B8",
    fontFamily:'Poppins',
    lineHeight:"24px",
    fontWeight:400,
  },
      });
const webStyles = {
    attachment_icon: {
      width: "24px",
      height: "24px",
      marginRight: "0.5rem",
      color: "#94A3B8",
      cursor:'pointer',
      },
      sendmsgInput: {
        border: "1px solid #C4CBD4",
        background: "#FCFDFE",
        borderRadius:'8px',
        color:'#94A3B8',
        padding:"10px 0px 10px 10px",
        fontFamily:'Poppins',
        width:'100%',
        fontWeight:400,
        fontSize:'16px',
        lineHeight:"24px",
        
      },
}

// Customizable Area End
