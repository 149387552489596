// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { projectCarouselData, projectDataType } from "./types";
import {
  ProjectList2,
  QCInitiated,
  sectionList,
  settingList1,
  ProjectInQueue,
  settingList,
} from '../__mocks__/KanbanInterface';
import { Alert, Platform } from "react-native";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface ApiData {
  contentType: string,
  method: string,
  endPoint: string,
  body?: object
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  projects: projectDataType,
  statusFlow: string[],
  data: {
    history: { location: { pathname: string } },
    projectStatusColors: projectCarouselData["projectStatusColors"],
    projects: projectDataType
  },
}

interface statusList {
  status:string,
  count:number,
  colorCode:string,
}



interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  ActiveKeyBoardControl: boolean,
  currentSlide: number,
  prevSlide: number,
  isVertical: boolean
  startDate: string,
  endDate: string,
  email: string,
  password: string,
  selectedStartDate: string;
  selectedEndDate: string;
  modalVisible: boolean;
  project_in_Queue: Array<ProjectInQueue>
  qc_Initiated: Array<QCInitiated>;
  statusFlow: string[]
  statusData: { attributes: { color: string, section_type: string; name: string } }[]
  settingData: { name: string, direction: string, theme: string, active: boolean }
  isHorizontal: boolean
  theam: string
  dataLength: number,
  toggle:boolean,
  isBulkAction:boolean,
  inputName:string,
  statusList:statusList[],
  priority:string;
  anchorEl:any;
  selectedAction:string;
  subMenuOpen: boolean;
  subMenuAnchorEl:any;
 isTaskListSelected:boolean;
 isAssignMemberModalOpen:boolean;
 selectedUsers: any;
 memberError:string;
 users: any;
 messageToggle: boolean;
 tostifymessage: string;
 status: string;


}

interface SS {
  id: any;

}

export default class KanbanBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  projectListApiId: string = ""
  sectionListApiId: string = ""
  settingListApiId: string = ""
  sectionAPIID: string = ""
  settingsAPIID: string = ""
  getTaskListCallId:string=""
  getProjectMembersCallId:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      ActiveKeyBoardControl: false,
      currentSlide: 0,
      prevSlide: 0,
      isVertical: false,
      startDate: '19/02/2023',
      endDate: '25/03/2023',
      email: "",
      password: "",
      selectedStartDate: '',
      selectedEndDate: '',
      modalVisible: false,
      qc_Initiated: [],
      project_in_Queue: [],
      statusFlow:  [],
      statusData: [],
      settingData: { name: "", direction: "", theme: "", active: false },
      isHorizontal: false,
      theam: '',
      dataLength: 0,
      toggle:true,
      isBulkAction:false,
      inputName:"",
      statusList:[ { status: "To-Do", count: 2, colorCode: "#60A5FA" },
      { status: "In Progress", count: 1, colorCode: "#F59E0B" },
      { status: "Completed", count: 5, colorCode: "#34D399" },
      { status: "Blocked", count: 4, colorCode: "#F87171" },],
      priority:"low",
      anchorEl:null,
      selectedAction: '',
    subMenuOpen: false,
    subMenuAnchorEl: null,
    isTaskListSelected:false,
    isAssignMemberModalOpen:false,
    selectedUsers: [],
    memberError:"",
    users: [],
    messageToggle: false,
    tostifymessage: "",
    status: "",

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    this.getTaskListApi()
    this.getProjectsMembers()
    if (Platform.OS != "web") {
      this.projectList()
      this.sectionList()
      this.settingList()
      await getStorageData("USER_TOKEN")
    } else {
      this.props.data.history.location.pathname == "/bb_taskboard" && await this.ContentAPICall() && this.DataSettingAPICall()
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.projectListApiId) {
        await this.projectListResponse(responseJson)
      }
      if (apiRequestCallId === this.sectionListApiId) {
        this.sectionListResponse(responseJson)
      }
      if (apiRequestCallId === this.settingListApiId) {
        this.settingListResponse(responseJson)
      }
      if(apiRequestCallId !== null){
        if(apiRequestCallId === this.getTaskListCallId){
          this.handleGetTaskListResponse(responseJson)
        }
        if(apiRequestCallId === this.getProjectMembersCallId){
          this.handleGetProjectMembersResponse(responseJson)
        }
        
      }
      

     

    }
  }

  handleGetProjectMembersResponse = (responseJson:any) =>{
    this.setState({users:responseJson.data})
  }

  handleGetTaskListResponse = (responseJson:any) =>{
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (JSON.stringify(prevProps.statusFlow) != JSON.stringify(this.props.statusFlow)) {
      this.setState({ statusFlow: this.props.statusFlow })
    }
  }

  apiCallFunction = async (data: ApiData) => {
    const { contentType, method, endPoint, body, } = data;

    const header = {
      "Content-Type": contentType,
      "token": await getStorageData("USER_TOKEN")
    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return requestMessagee.messageId;
  };

  ContentAPICall = async () => {
    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  DataSettingAPICall = async () => {

    const header = {
      "token": await getStorageData("token")

    };
    const requestMessagee = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.settingsAPIID = requestMessagee.messageId;
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.settingsEndPoint
    );

    runEngine.sendMessage(requestMessagee.id, requestMessagee);
    return true
  };

  projectListResponse = async (responseJson: ProjectList2) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successOrderResponse(responseJson)
      }

    }
  }
  sectionListResponse = (responseJson: sectionList) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSectionListResponse(responseJson)
      }

    }
  }
  settingListResponse = (responseJson: settingList1) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successSettingListResponse(responseJson)
      }

    }
  }
  projectList = async () => {
    this.projectListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_taskallocator/kanbanboard?from=${this.state.startDate}&to=${this.state.endDate}`,
    });
  }
  sectionList = async () => {
    this.sectionListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/sections`,
    });
  }
  settingList = async () => {
    this.settingListApiId = await this.apiCallFunction({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_kanbanboard/settings`,
    });
  }
  successOrderResponse(responseJson: ProjectList2) {
    this.setState(
      {
        project_in_Queue: responseJson['Project in Queue'],
        dataLength: responseJson['Project in Queue'].length
      }
    )
  }
  successSectionListResponse(responseJson: sectionList) {
    let tempArray: string[] = []
    responseJson.data.map((item: { attributes: { section_type: string } }) => {
      tempArray.push(item.attributes.section_type)
    })
    this.setState({ statusFlow: tempArray, statusData: responseJson.data })
  }

  successSettingListResponse(responseJson: settingList) {
    responseJson.data.map((item: { attributes: { active: boolean } }, index: number) => {
      if (item.attributes.active) {
        let theme = responseJson.data[index].attributes.theme
        let dirction = responseJson.data[index].attributes.direction === "vertical"
        this.setState({ theam: theme, isHorizontal: dirction })
      }
    })
  }


  goBack = () => {
    Alert.alert("Logout")
    removeStorageData("USER_TOKEN")
    const message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  getTaskListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
 
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTaskListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetTaskListEndPoint}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleToggle = async(tab: string) => {
    const isTeamsTab = tab === "Kanban Board View";
    localStorage.setItem("selectedTab", tab);
  
    this.setState({
      toggle: isTeamsTab,
    })
  };

  handleFilter =()=>{
    this.setState({
      toggle:true
    })
  }

  handleSearch = (inputData:string) => {
    const value = inputData.includes(this.state.inputName)
    this.setState({
      isBulkAction:false
     })
  }

  handleMenuOpen = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, subMenuOpen: false });
  };

  handleActionClick = (action: string, event: any) => {
    if (action === 'Change Status') {
      this.setState({
        selectedAction: action,
        subMenuOpen: true,
        subMenuAnchorEl: event.currentTarget,
      });
    } else {
      this.setState({ selectedAction: action,
        isAssignMemberModalOpen:true
       });
      this.handleMenuClose();
    }
  };

  handleSubMenuClick = (status:string) => {
    this.handleMenuClose();
  };

  handleChangetaskListSelect = ()=>{
 this.setState({
  isTaskListSelected:!this.state.isTaskListSelected
 })
  }

  handleAssignMember = ()=>{
     this.setState({
      isAssignMemberModalOpen:false,
     })
     this.setState({
      messageToggle: true,
      tostifymessage: "Member(s) successfully assigned.",
      status: "success",
    });
    setTimeout(() => {
      this.setState({ messageToggle: false, tostifymessage: "", status: "" });
    }, 5000);
  }

  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAssignMemberModalOpen: !prev.isAssignMemberModalOpen,
    }));

    getStatusBackgroundColor = (status:string) => {
      switch (status) {
        case 'To Do':
          return '#3B82F6'; 
        case 'In Progress':
          return '#FBBF24'; 
        case 'Completed':
          return '#34D399'; 
        case 'Blocked':
          return '#F87171'; 
        case 'Archived':
          return '#64748B'; 
        default:
          return ''; 
      }
    };

  getProjectsMembers = async () => {
      const token = await getStorageData("token")
      const header = {
        "Content-Type": "application/json",
        token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getProjectMembersCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_chat/chat_memberships?chat_channel_id=172`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
  

}


// Customizable Area End
