import React from "react";

import {
    // Customizable Area Start
    ThemeProvider,
    Box,
    Typography,
    Grid,
    Button,
    styled,
    IconButton,
    MenuItem,
    Dialog,
    DialogActions,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    createStyles,
    Theme,
    TableCell,
    Divider,
    TextField,
    OutlinedTextFieldProps,
    TableFooter,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    Select,
    FormControl,
    ListItemText,
    Paper,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { DeleteOutline, EditSharp, MoreVert, Clear, Check } from "@material-ui/icons";
import { Formik } from "formik";
import * as yup from "yup";
import { createTheme } from "@mui/material/styles";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { backButton, warningSign, settingIcon, arrowNext, folderIcon, pdfIcon, deleteIcon, arrowUpIcon, unselectedRadioButton, selectedRadioButton, percentageIcon, addPlusIcon } from "./assets";

interface Room {
    id: string;
    label: string;
  }
const theme = createTheme({
    palette: {
        primary: {
            main: "#9b59b6",
            contrastText: "#fff",
        },
        secondary: {
            main: "#f3f4f6",
        },
    },
});


const files = [
    { id: '1', name: 'File name.pdf', size: '10 MB' },
    { id: '2', name: 'File name.pdf', size: '10 MB' },
]
const files2 = [
    { id: '1', name: 'File name.pdf', size: '10 MB' },
    { id: '2', name: 'File name.pdf', size: '10 MB' },
]

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        
      head: {
        padding:'11.5 !important',
        borderBottom:'1px solid #E2E8F0',
        backgroundColor: '#FFFFFF',
        color:'#475569',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '22px',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
      },
      body: {
        padding:'11.8 !important',
        color:'#0F172A',
        borderBottom:'1px solid #E2E8F0',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
    })
  )(TableCell);
  
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: '#F0F5F7',
        },
      },
    })
  )(TableRow);
  


 
// Customizable Area End

import QuoteOverviewController, {
    Props
} from "./QuoteOverviewController";



export default class QuoteOverview extends QuoteOverviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    isNumeric = (value: string): boolean => /^\d*$/.test(value);

    handleRetentionPercentageChange = (formik: any) => (e: any) => {
      const { value } = e.target;
      if (this.isNumeric(value)) {
        formik.setFieldValue('retentionPercentage', value);
      }
    };
    getAdjustedCost = (): string => {
      const { adjustedCost } = this.state.ProjectDetails;
      return adjustedCost || "No Cost Adjustments Applied";
    };
    formSchema = () =>
        yup.object().shape({
            retentionPercentage: yup
            .string()
            .min(0, 'Percentage must be at least 0')
            .required("Please enter retention percentage")
        });
    getError = (fieldName:any, formik: any, backendErrors:any = {}) => {
            const isTouched = formik.touched[fieldName];
            const validationError = formik.errors[fieldName];
            const backendError = backendErrors[fieldName];
          
            if (backendError) {
              return backendError;
            }
          
            if (isTouched && validationError) {
              return validationError;
            }
          
            return '';
          };    
    InfoCard = ({ id, title, taskCount, cost, onEdit, onDelete }: any) => {
      
        return (
            <Grid item xs={12} sm={6} lg={3} xl={3} style={{ padding: '10px' }}>
                <Paper className="third_grid_box" style={{ borderRadius: '8px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography className="thirdGridHeadingTypo">{title}</Typography>
                        </Box>
                        <Box sx={{ marginRight: '-9px', position: 'relative' }}>
                            <IconButton data-test-id="handleOpenActiaonTab" onClick={() => this.handleOpenActiaonTab(id)}>
                                <MoreVert style={{ color: "#475569" }} />
                            </IconButton>
                            {this.state.selectedTaskId === id && (
                                <div className="menu" style={{
                                    position: 'absolute',
                                    border: "1px solid #E2E8F0",
                                    backgroundColor: '#fff',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                                    padding: '4px 10px',
                                    zIndex: 1000,
                                    marginLeft: '-94px'
                                }}>
                                    <MenuItem
                                        data-test-id="handleRenameProject"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onEdit();
                                            this.setState({ selectedTaskId: null })
                                        }}
                                    >
                                        <EditSharp style={{ height: '18px', marginRight: '8px', marginTop: '-6px' }} />
                                        Edit
                                    </MenuItem>

                                    <MenuItem
                                        data-test-id="handleDeleteProject"
                                        onClick={() => {
                                            onDelete();
                                            this.setState({ selectedTaskId: null });
                                        }}
                                        style={{ color: '#DC2626', alignItems: 'flex-start' }}
                                    >
                                        <DeleteOutline style={{ height: '20px', marginRight: '8px' }} />
                                        Delete
                                    </MenuItem>
                                </div>
                            )}
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Box>
                            <Box className="thirdGridDataTypoBox">
                                <Typography className="thirdGridDataTypo">Tasklist:</Typography>&nbsp;
                                <Typography className="thirdGridDataTypo">{taskCount}</Typography>
                            </Box>
                            <Box className="thirdGridDataTypoBox">
                                <Typography className="thirdGridDataTypoBold">Cost:</Typography>&nbsp;
                                <Typography className="thirdGridDataTypoBold">£ {cost}</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <img src={settingIcon} alt="icon" />
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        );
    };
    renderFileBlock = (file: { name: string; size: string }, index: number) => {
        return (
            <Paper
                key={index}
                style={{
                    boxShadow: 'rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px',
                    borderRadius: '8px',
                    padding: '6px 8px',
                    marginTop: '16px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                    <Box>
                        <img src={pdfIcon} alt="pdfIcon" />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography className="fileNameTypo">{file.name}</Typography>
                        <Typography className="fileSizeTypo">{file.size}</Typography>
                    </Box>
                </Box>
                <Box>
                    <img src={deleteIcon} alt="deleteIcon" />
                </Box>
            </Paper>
        );
    };
    rooms: Room[] = [
        { id: 'bathroom', label: 'Bathroom' },
        { id: 'bathroom_1', label: 'Bathroom (1)' },
        { id: 'bathroom_2', label: 'Bathroom (2)' },
        { id: 'bedroom', label: 'Bedroom' },
        { id: 'corridor', label: 'Corridor / Hall' },
        { id: 'livingRoom', label: 'Living Room' },
        { id: 'kitchen', label: 'Kitchen' },
      ];
      getFilteredRooms = (key: string) => {
        const allRooms = [
          { id: 'bathroom', label: 'Bathroom' },
          { id: 'bedroom', label: 'Bedroom' },
          { id: 'kitchen', label: 'Kitchen' },
        ];
        const { searchText } = this.state.dropdowns[key];
        return allRooms.filter((room) =>
          room.label.toLowerCase().includes(searchText.toLowerCase())
        );
      };
    

      renderPaymentOption = (option: string, label: string,testId:string) => {
        return (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              cursor: 'pointer',
            }}
            //data-test-id="handlePaymentOptionChange"
            data-test-id={testId}
            onClick={() => this.handlePaymentOptionChange(option)}
          >
            <img
              src={this.state.PaymentOptionChangeValue === option ? selectedRadioButton : unselectedRadioButton}
              alt="radio button"
            />
            <Typography className="paymentOptionTypo">{label}</Typography>
          </Box>
        );
      };
    

      
      
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { data } = this.state.roomsAndTaskListsCard;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <MainWrapper>
                        
                        <MyProfileHeader>

                       <CustomDialog data-test-id="CustomDeletePopup" PaperProps={{
                      style: {
                        height: "232px",
                        width: '584px',
                        borderRadius: "16px",
                        padding: "40px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openDialog} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography className="title" >Are you sure you want to delete this task? </Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                          marginTop: "32px",
                          gap: "16px",
                          padding: "0px"
                        }}>
                          <Button data-test-id="handleCloseDialog" className="cancelButton" onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button  data-test-id="handleConfirmDelete" className="confirmButton" onClick={() => this.handleConfirmDelete()}>
                           Confirm
                          </Button></DialogActions>

                      </Box>
                       </CustomDialog> 

                       <CustomDialogSelectPaymentPlan 
                       data-test-id="add_payment_plan_popup"
                       PaperProps={{
                      style: {
                        maxWidth:'735px',
                        borderRadius: "16px",
                        padding: "32px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} 
                    open={this.state.openSelectPaymentDialog} onClose={this.handleCloseSelectPaymentPlanDialog}>
                         <form data-test-id="handleSubmitSelectPaymentPlan" onSubmit={this.handleSubmitSelectPaymentPlan}>    
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography className="title" >Select Payment Plan</Typography> <Typography onClick={this.handleCloseSelectPaymentPlanDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <Box sx={{marginTop:'32px'}}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Paper data-test-id="handleRadioChangeForMilestone" onClick={()=>this.handleRadioChange('Milestones')} className={this.state.selectPlanValue==='Milestones'?'dialogPaperSelected':'dialogPaperNotSelected'}>
                                        <Box style={{display:'flex',alignItems:'center',gap:'8px'}}>
                                            <Box>
                                                {this.state.selectPlanValue==="Milestones" ? <img src={selectedRadioButton}/>:<img src={unselectedRadioButton}/>}
                                            </Box>
                                            <Box>
                                                <Typography className="dialogLabel">Milestones</Typography>   
                                            </Box>
                                        </Box>
                                        <Box sx={{marginTop:'8px'}}>
                                            <Typography className="dialodDescTypo">
                                                Milestone - based payment plan, paid before/after each stage.
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Paper data-test-id="handleRadioChangeForValuation" onClick={()=>this.handleRadioChange('Valuations')} className={this.state.selectPlanValue==='Valuations'?'dialogPaperSelected':'dialogPaperNotSelected'}>
                                        <Box style={{display:'flex',alignItems:'center',gap:'8px'}}>
                                            <Box>
                                                {this.state.selectPlanValue==="Valuations" ? <img src={selectedRadioButton}/>:<img src={unselectedRadioButton}/>}
                                            </Box>
                                            <Box>
                                                <Typography className="dialogLabel">Valuations</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{marginTop:'8px'}}>
                                            <Typography className="dialodDescTypo">
                                                Customized payments based on combination of rooms selected.  
                                            </Typography>
                                        </Box> 
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                        <DialogActions style={{
                          marginTop: "32px",
                          gap: "16px",
                          padding: "0px"
                        }}>
                          <Button type='submit' data-test-id="handleConfirmDelete" className="confirmButton">
                           Next
                          </Button></DialogActions>

                      </Box>
                      </form>
                       </CustomDialogSelectPaymentPlan>     


                       <CustomDialogMilestonesPaymentPlan
                       data-test-id="CustomDialogMilestone_popup"
                       PaperProps={{
                      style: {
                        maxWidth:'1020px',
                        borderRadius: "16px",
                        padding: "24px 24px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} 
                    open={this.state.openMilestonesPaymentPlanDialog} onClose={this.handleCloseMilestonesPaymentPlanDialog}>
                          
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography className="title" > Milestones Payment Plan</Typography> <Typography onClick={this.handleCloseMilestonesPaymentPlanDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <Box sx={{marginTop:'8px'}}><Typography className="milestonesDialogDescription">Set up payments based on milestones. You can choose whether the payment is made before or after the milestone.</Typography></Box>
                          <Box sx={{marginTop:'10px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={8}>
                                  
                                        <TableContainer style={{borderRadius:'8px', border:'1px solid #E2E8F0'}} component={Paper}>
        <Table className="table" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Invoice Name</StyledTableCell>
              <StyledTableCell align="center">Due Date</StyledTableCell>
              <StyledTableCell align="center">Invoice Price</StyledTableCell>
              <StyledTableCell align="center">Retained Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.invoices.map((row) => (
              <StyledTableRow key={row.invoiceName}>
                <StyledTableCell component="th" scope="row">
                  {row.invoiceName}
                </StyledTableCell>
                <StyledTableCell align="center">{row.dueDate}</StyledTableCell>
                <StyledTableCell align="center">£{row.invoicePrice}</StyledTableCell>
                <StyledTableCell align="center">{row.retainedAmount}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                    <Formik
                                    initialValues={{paymentOption:this.state.PaymentOptionChangeValue,retentionPercentage:''}}
                                    validationSchema={this.formSchema}
                                    onSubmit={this.handleSubmit}
                                    enableReinitialize
                                    validateOnMount={true}
                                    data-test-id="formik_milestone"
                                    >
                                        {(formik) => (
                                            <>
                                            <form  onSubmit={formik.handleSubmit}>
                                        
                                        <Box style={{display:'flex',flexDirection:'column',gap:'12px'}}>
                                            <Box><Typography className="paymentOptionTitleTypo">Payment Option*:</Typography></Box>
                                            {this.renderPaymentOption('payAfter', 'Pay after stage end date','payAfterTestId')}
                                           {this.renderPaymentOption('payBefore', 'Pay before stage start date','payBeforeTestId')}
                                        </Box>
                                        <Divider style={{marginTop:'16px',marginBottom:'16px'}}/>
                                        <Box  style={{display:'flex',flexDirection:'column',gap:'12px'}}>
                                            <Box><Typography className="addRetentionPeriodTypo">Add Retention Period</Typography></Box>
                                            <Box><Typography className="RetentionPeriodDescriptionTypo">You can retain a percentage of the total payment and specify a period after which the retained amount  will be due. </Typography></Box>
                                            <Box>
                                                <Typography className="RetentionPeriodInputTypo">Retention Percentage:</Typography>
                                                <Box sx={{marginTop:'5px'}}>
                                                <CustomTextField 
                                                autoComplete="off"
                                                hasValue={!!formik.values.retentionPercentage}
                                                name="retentionPercentage"
                                                data-test-id="retentionPercentage"
                                                value={formik.values.retentionPercentage}
                                                // onChange={(e:any) => {
                                                //   const value = e.target.value;
                                                //   if (/^\d*$/.test(value)) {
                                                //     formik.setFieldValue('retentionPercentage', value);
                                                //   }
                                                // }}
                                                onChange={this.handleRetentionPercentageChange(formik)}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(this.getError('retentionPercentage', formik))}
                                                helperText={this.getError('retentionPercentage', formik)}
                                                FormHelperTextProps={{
                                                    style: { marginLeft: 0,
                                                        color: '#FF0000',
                                                        fontSize: 'small',
                                                        marginTop: '2px',
                                                        fontFamily: 'Poppins'
                                                    },
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter percentage (e.g., 10%)"
                                                inputProps={{
                                                    className: 'custom-input',
                                                    min: 0,
                                                    max: 100,
                                                    style: {
                                                        color:'#000000',
                                                        fontFamily: 'Poppins',
                                                        fontSize: '16px',
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        textAlign: 'right',
                                                        textUnderlinePosition: 'from-font',
                                                        textDecorationSkipInk: 'none',
                                                    },

                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                          edge="end"
                                                          style={{ color: '#64748B' }}>
                                                           <img src={percentageIcon} alt="img"/>
                                                        </IconButton>
                                                      )
                                                      
                                                }}
                                            />
                                                    
                                                </Box>
                                            </Box>
                                            <Box style={{display:'flex',gap:'16px',marginTop:'5px'}}>
                                                <Box sx={{width:'100%'}}>
                                                <CancelButton onClick={this.handleCloseMilestonesPaymentPlanDialog}>Cancle</CancelButton>
                                                </Box>
                                                <Box sx={{width:'100%'}}>
                                                <SaveButton data-test-id="savePlan" type='submit' disabled={!formik.isValid || formik.isSubmitting || !this.state.PaymentOptionChangeValue}>Save Plan</SaveButton>   
                                                </Box>
                                            </Box>
                                            <Box sx={{display:'flex',marginTop:'-10px'}}>
                                                <Box sx={{width:'100%'}}>
                                                <Divider style={{marginTop:'16px',marginBottom:'16px',marginRight:'10px'}}/>
                                                </Box>
                                                <Box>
                                                    <Typography className="orTypo">or</Typography>
                                                </Box>
                                                <Box sx={{width:'100%'}}>
                                                <Divider style={{marginTop:'16px',marginBottom:'16px',marginLeft:'10px'}}/>
                                                </Box>  
                                            
                                            </Box>
                                            <Box sx={{marginTop:'-12px'}}>
                                                <Typography data-test-id="handleSwitchPaymentPlan" onClick={()=>this.handleSwitchPaymentPlan('Valuations')} className="switchButtonTypo">
                                                Switch to Valuations
                                                </Typography>
                                            </Box>

                                        </Box>
                                        <Box>

                                        </Box>
                                        </form>
                                        </>
                                        )}
                                        </Formik>
                                    </Box>
                                  
                                </Grid>
                            </Grid>
                          </Box>
                  

                      </Box>
                    
                       </CustomDialogMilestonesPaymentPlan>     




                       <CustomDialogValuationsPaymentPlan
                       data-test-id="CustomDialogValuations_popup"
                       PaperProps={{
                      style: {
                        maxWidth:'1020px',
                        borderRadius: "16px",
                        padding: "24px 24px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} 
                    open={this.state.openValuationsPaymentPlanDialog} onClose={this.handleCloseValuationsPaymentPlanDialog}>
                          
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography className="title" > Valuations Payment Plan</Typography> <Typography onClick={this.handleCloseValuationsPaymentPlanDialog} style={{color: "black",width: "20px"
                        }}><Clear />
                        </Typography>
                        </Box>
                        <Box sx={{
                          marginTop:'8px'
                          }}>
                            <Typography className="milestonesDialogDescription">
                              Set up payments based on milestones. You can choose whether the payment is made before or after the milestone.</Typography></Box>
                          <Box sx={{
                            marginTop:'10px'
                            }}>
                            <Grid
                             container 
                             spacing={3}>
                                <Grid 
                                item 
                                xs={12} 
                                sm={8}>
                                  
                                        <TableContainer 
                                        style={{
                                          borderRadius:'8px', 
                                          border:'1px solid #E2E8F0'
                                          }} 
                                          component={Paper}>
        <Table 
        className="table" 
        aria-label="customized table">
           <TableHead>
             <TableRow>
              <StyledTableCell>
                Invoice Name
                </StyledTableCell>
              <StyledTableCell align="center">
                Due Date
                </StyledTableCell>
              <StyledTableCell align="center">
                Invoice Price
                </StyledTableCell>
              <StyledTableCell align="center">
                Retained Amount
                </StyledTableCell>
              </TableRow>
              </TableHead>
           <TableBody>
            {this.state.invoices.map((invoice) => (
              <StyledTableRow key={invoice.invoiceName}>
                <StyledTableCell component="th" scope="row">
                <FormControl fullWidth>
                  <CustomSelect
                  data-test-id="select_room"
  variant="outlined"
  multiple
  open={this.state.dropdowns[invoice.id]?.isDropdownOpen}
  value={this.state.dropdowns[invoice.id]?.selectedRooms || []}
  onOpen={() => this.handleDropdownOpen(invoice.id)}
  onClose={() => this.handleDropdownClose(invoice.id)}
 
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: '226px',
        width: '160px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        boxShadow: 'none',
        padding: '8px',
        overflowY: 'auto',
        marginLeft:'-9px',
        marginTop:'47px'
      },
    },
    MenuListProps: {
      style: {
        padding: '0',
      },
    },
  }}
  renderValue={(selected: any) => {
    if (selected.length === 0) {
      return <span style={{ color: '#A0A0A0' }}>Select Room(s)</span>;
    }
    return (selected as string[])
      .map((roomId) =>
        this.getFilteredRooms(invoice.id).find((room) => room.id === roomId)?.label || ''
      )
      .join(', ');
  }}
  
>

  <Box padding={1} style={{}}>
    <CustomSearchField
      data-test-id='handleSearchChange'
      variant="outlined"
      value={this.state.dropdowns[invoice.id]?.searchText || ''}
      onChange={(e) => this.handleSearchChange(invoice.id, e.target.value)}
      placeholder="Search and type..."
      fullWidth
      size="small"
    />
  </Box>


  <Box style={{ maxHeight: '180px', overflowY: 'auto', marginTop:'-15px' }}>
    <List>
      {this.getFilteredRooms(invoice.id).map((room) => (
        <ListItem
          data-test-id="handleCheckboxToggle"
          button
          key={room.id}
          onClick={() => this.handleCheckboxToggle(invoice.id, room.id)}
        >
          <ListItemIcon>
            <Checkbox
              checked={this.state.dropdowns[invoice.id]?.tempSelectedRooms.includes(room.id)}
              style={{
                padding: 0,
                borderRadius: '6px',
                width: '20px',
                height: '20px',
                pointerEvents: 'auto',
              }}
              icon={
                <span
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '6px',
                    width: '20px',
                    height: '20px',
                    border: '1px solid #64748B',
                    display: 'inline-block',
                  }}
                />
              }
              checkedIcon={
                <span
                  style={{
                    backgroundColor: '#325962',
                    color: '#fff',
                    borderRadius: '6px',
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    border: '1px solid #325962',
                  }}
                >
                  <Check style={{ color: 'white', fontSize: 18 }} />
                </span>
              }
              data-test-id="handleToggleItem"
            />
          </ListItemIcon>
          <ListItemText primary={
           <Typography style={{
            color:'#0F172A',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            marginLeft:'-27px'

           }}>{room.label}</Typography>
            } />
        </ListItem>
      ))}
    </List>
  </Box>

  <Box display="flex" style={{gap:'16px'}} padding={1}>
    <CancelButton2
      data-test-id="handleCancel"
      onClick={() => this.handleCancel(invoice.id)}
      color="secondary"
      variant="outlined"
    >
      Cancel
    </CancelButton2>
    <AddButton
      data-test-id="handleAdd"
      onClick={() => this.handleAdd(invoice.id)}
      color="primary"
      variant="contained"
      disabled={this.state.dropdowns[invoice.id]?.tempSelectedRooms.length === 0}
    >
      Select
    </AddButton>
  </Box>
</CustomSelect>

                  </FormControl>
                </StyledTableCell>
                <StyledTableCell align="center">{invoice.dueDate}</StyledTableCell>
                <StyledTableCell align="center">£{invoice.invoicePrice}</StyledTableCell>
                <StyledTableCell align="center">{invoice.retainedAmount}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <Box style={{display:'flex', padding:'12px 24px 12px 24px', gap:'8px',alignItems:'center'}}>
           <Box>
            <img src={addPlusIcon} alt="add"/>
           </Box>
           <Box>
           <Typography className="addAnotherInvoiceTypo">Add Another Invoice</Typography>
           </Box>
           </Box>
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
      <Box marginTop={'16px'}>
        <Typography className="tableFooterTypo">[Auto-calculated based on selected rooms]</Typography>
      </Box>
                                </Grid>
                                
                                <Grid 
                                item 
                                xs={12} 
                                sm={4}>
                                    <Box>
                                      <Formik initialValues={{paymentOption:this.state.PaymentOptionChangeValue,retentionPercentage:''}}
                                    validationSchema={this.formSchema} onSubmit={this.handleSubmit}
                                    enableReinitialize validateOnMount={true}
                                    data-test-id="formik_valuations"
                                    >
                                      {(formik) => (<>
                                            <form  
                                              onSubmit={formik.handleSubmit}>
                                                <Box 
                                                style={{display:'flex',
                                                flexDirection:'column',
                                                gap:'12px'
                                                }}>
                                            <Box>
                                              <Typography 
                                              className="paymentOptionTitleTypo">Payment Option*:
                                              </Typography>
                                              </Box>
                                           {this.renderPaymentOption('payAfter', 'Pay after stage end date','payAfterTestId')}
                                           {this.renderPaymentOption('payBefore', 'Pay before stage start date','payBeforeTestId')}
                                        </Box>
                                        <Divider 
                                        style={{
                                          marginTop:'16px',
                                          marginBottom:'16px'
                                          }}/>
                                        <Box  style={{
                                          display:'flex',
                                          flexDirection:'column',
                                          gap:'12px'}}>
                                            <Box>
                                              <Typography 
                                              className="addRetentionPeriodTypo">Add Retention Period
                                              </Typography>
                                              </Box>
                                            <Box>
                                              <Typography 
                                              className="RetentionPeriodDescriptionTypo">You can retain a percentage of the total payment and specify a period after which the retained amount  will be due. 
                                              </Typography>
                                              </Box>
                                            <Box>
                                                <Typography className="RetentionPeriodInputTypo">
                                                  Retention Percentage:
                                                  </Typography>
                                                <Box sx={{
                                                  marginTop:'5px'
                                                  }}>
                                                <CustomTextField 
                                                autoComplete="off" hasValue={!!formik.values.retentionPercentage}
                                                name="retentionPercentage" data-test-id="retentionPercentage"
                                                value={formik.values.retentionPercentage}
                                                // onChange={(e:any) => {
                                                //   const value = e.target.value;
                                                //   if (/^\d*$/.test(value)) {
                                                //     formik.setFieldValue('retentionPercentage', value);
                                                //   }
                                                // }}
                                                onChange={this.handleRetentionPercentageChange(formik)}
                                                onBlur={formik.handleBlur} error={Boolean(this.getError('retentionPercentage', formik))}
                                                helperText={this.getError('retentionPercentage', formik)}
                                                FormHelperTextProps={{
                                                    style: { marginLeft: 0, color: '#FF0000', fontSize: 'small',
                                                        marginTop: '2px',fontFamily: 'Poppins'
                                                    },
                                                }}
                                                variant="outlined" fullWidth
                                                placeholder="Enter percentage (e.g., 10%)"
                                                inputProps={{
                                                    className: 'custom-input', min: 0,
                                                    max: 100,
                                                    style: {color:'#000000',
                                                        fontFamily: 'Poppins',fontSize: '16px',
                                                        fontWeight: 400,lineHeight: '24px',
                                                        textAlign: 'right',textUnderlinePosition: 'from-font',
                                                        textDecorationSkipInk: 'none',},
                                                }}
                                                InputProps={{endAdornment: (
                                                        <IconButton edge="end"
                                                          style={{ 
                                                            color: '#64748B' 
                                                            }}>
                                                           <img 
                                                           src={percentageIcon}
                                                            alt="img"/>
                                                        </IconButton>
                                                      )
                                                }}
                                            /></Box>
                                            </Box>
                                            <Box 
                                            style={{display:'flex',
                                            gap:'16px',
                                            marginTop:'5px'}}>
                                                <Box sx={{
                                                  width:'100%'
                                                  }}>
                                                <CancelButton 
                                                onClick={this.handleCloseMilestonesPaymentPlanDialog}>
                                                  Cancle
                                                  </CancelButton>
                                                </Box>
                                                <Box sx={{
                                                  width:'100%'
                                                  }}>
                                                <SaveButton 
                                                data-test-id="savePlan"
                                                type='submit' 
                                                disabled={!formik.isValid || formik.isSubmitting || !this.state.PaymentOptionChangeValue}>Save Plan</SaveButton>   
                                                </Box>
                                            </Box>
                                            <Box sx={{
                                              display:'flex',
                                              marginTop:'-10px'
                                              }}>
                                                <Box sx={{
                                                  width:'100%'
                                                  }}>
                                                <Divider style={{
                                                  marginTop:'16px',
                                                  marginBottom:'16px',
                                                  marginRight:'10px'}}/>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                     className="orTypo">
                                                      or
                                                      </Typography>
                                                </Box>
                                                <Box sx={{
                                                  width:'100%'
                                                  }}>
                                                <Divider style={{
                                                  marginTop:'16px',
                                                  marginBottom:'16px',
                                                  marginLeft:'10px'
                                                  }}/>
                                                </Box>  
                                            </Box>
                                            <Box sx={{
                                              marginTop:'-12px'
                                              }}>
                                                <Typography data-test-id="handleSwitchPaymentPlan" onClick={()=>this.handleSwitchPaymentPlan('Milestones')} className="switchButtonTypo">
                                                Switch to Milestones
                                                </Typography>
                                            </Box>

                                        </Box>
                                        <Box>

                                        </Box>
                                        </form>
                                        </>
                                        )}
                                        </Formik>
                                    </Box>
                                  
                                </Grid>
                            </Grid>
                          </Box>
                        

                      </Box>
                    
                       </CustomDialogValuationsPaymentPlan>    




                        <Box sx={{ padding: '30px 40px', bgcolor: '#F0F5F7' }}>
                            <Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box>
                                            <img src={backButton} alt="back" />
                                        </Box>
                                        <Box style={{cursor:"pointer"}} onClick={this.backNavigation}>
                                            <Typography className="backButtonTypo">Back</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                        <Box style={{ marginLeft: '370px' }}>
                                            <Typography className="mainTitleTypo">Quote Overview</Typography>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: 16 }}>
                                        <Box>
                                            <CustomViewAndAddMemberButton>
                                                View & Add Member
                                            </CustomViewAndAddMemberButton>
                                        </Box>
                                        <Box>
                                            <CustomSaveDraftButton>
                                                Save Draft
                                            </CustomSaveDraftButton>
                                        </Box>
                                        <Box>
                                            <CustomGenerateQuoteButton>
                                                Generate Quote
                                            </CustomGenerateQuoteButton>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                            <Box sx={{ marginTop: '30px' }}>
                                <Box>
                                    <Typography className="paperHeading">
                                        Project Details
                                    </Typography>
                                </Box>
                                <Paper className="main_paper">
                                    <Grid container spacing={4}>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="first_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Project Name:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="paperFirstDataTypo">{this.state.ProjectDetails.projectName}</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="first_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Client Details:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="paperFirstDataTypo">{this.state.ProjectDetails.clientFirstName} {this.state.ProjectDetails.clientLastName}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="paperFirstDataTypo">{this.state.ProjectDetails.clinetEmail}</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="first_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Address:</Typography>
                                                </Box>
                                                <Box>
                                                   <Typography style={{
                                                     color:'#0F172A',
                                                     fontFamily: 'Poppins',
                                                     fontSize: '16px',
                                                     fontWeight: 400,
                                                     lineHeight: '24px',
                                                     textAlign: 'left',
                                                     textUnderlinePosition: 'from-font',
                                                     textDecorationSkipInk: 'none',
                                                   }}>
                                                    {[
                                                        this.state.ProjectDetails.clientStreetName,
                                                        this.state.ProjectDetails.clientCityName,
                                                        this.state.ProjectDetails.clientStateName,
                                                        this.state.ProjectDetails.clientCountryName,
                                                        this.state.ProjectDetails.clientPostCode,
                                                    ].filter((value) => value).join(', ')} 
                                                    </Typography>

                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="first_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTwoTypoRight">Total Cost:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="paperFirstDataTwoTypo">£ {this.state.ProjectDetails.totalCost}</Typography>
                                                </Box>

                                                <Box sx={{display:'flex',justifyContent:'end'}}>
                                                    <AdjustCostButton>Adjusted Cost</AdjustCostButton>
                                                </Box>
                                                <Box sx={{ marginTop: '-2px' }}>
                                                    <Typography className="paperFirstDataTwoTypo">{this.getAdjustedCost()}</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Box>
                            <Box className="main_box">
                                <Box className="inLineBox">
                                    <Box>
                                    <Typography className="paperHeading2">
                                        Payment Plan & Adjusted Cost 
                                    </Typography>
                                    </Box>
                                    <Box>
                                    <Typography data-test-id="editPlan" onClick={()=>{this.editPlan()}} className="editButtonTypo">Edit</Typography>
                                    </Box>
                                </Box>
                                <Paper className="main_paper1">
                                    <Grid container spacing={0}>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="second_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Plan Type:</Typography>
                                                </Box>
                                                <Box className="second_grid_inner_box">
                                                    <Box> <img src={warningSign} alt='image' /></Box>
                                                    <Box>
                                                        <Typography className="paperWarnDataTypo">Please add a payment plan.</Typography>
                                                    </Box>

                                                </Box>
                                                <Box>
                                                    <Typography data-test-id="handleOpenSelectPaymentDialog" onClick={this.handleOpenSelectPaymentDialog} className="paperAddPaymentPlanTypo">Add Payment Plan</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="second_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Payment Date Option:</Typography>
                                                </Box>
                                                <Box>

                                                    <Typography>Please select</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="second_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Retention Percentage:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>Please select</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Box className="second_grid_box">
                                                <Box>
                                                    <Typography className="paperFirstHeadingTypo">Number of Invoices:</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>Please select</Typography>
                                                </Box>
                                            </Box>

                                        </Grid>

                                    </Grid>
                                </Paper>
                            </Box>
                            <Box className="main_box">
                                <Box className="inLineBoxUpper">
                                    <Box className="inLineBox2">
                                    <Box>
                                    <Typography className="paperHeading">
                                        Rooms & Tasklists
                                    </Typography>
                                    </Box>
                                    <Box>
                                    <Typography className="taskDataTypo">
                                      {`Total Tasks: ${data.length}`}
                                    </Typography>
                                    </Box>
                                    </Box>

                                    <Box>
                                    {this.state.viewAllTasks &&   
                                    <Box className="showLessBox">
                                    <Box>    
                                    <img style={{ marginBottom:'-5px',}} src={arrowUpIcon} alt='image'/>    
                                    </Box>
                                    <Box>
                                    <Typography data-test-id='handelViewAll' onClick={()=>this.handelViewAll()} className="collapseList">Show less</Typography>
                                    </Box>
                                    </Box>
                                    }
                                    </Box>
                                   
                                 
                                    
                                </Box>
                                <Box className="main_paper2">

                                    <Grid container spacing={3}>
                                        {data.slice(this.state.viewAllTasks ? 0 : -3).reverse().map((item: any, index: any) => (
                                            <this.InfoCard
                                                id={item.id}
                                                key={index}
                                                title={item.attributes.name}
                                                taskCount={item.attributes.tasks.length}
                                                cost={item.cost}
                                                icon={item.icon}
                                                onEdit={() => this.handleEditTaskList(item.id)}
                                                onDelete={() => this.handleDeleteTaskList(item.id)}
                                            />
                                        ))}
                                       {!this.state.viewAllTasks && <Grid item xs={12} sm={6} lg={3} xl={3}>
                                            <Paper className="third_grid_box" style={{ marginTop: '-2px', textAlign: 'center', height: '122', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Box>
                                                        <Typography data-test-id='handelViewAll2' onClick={()=>this.handelViewAll()} className="viewAllTaskList">
                                                        {`View All ${data.length} Rooms / Areas`}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <img src={arrowNext} alt="arrow" />
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </Grid>}

                                    </Grid>

                                </Box>
                            </Box>
                            <Box className="main_box">
                                <Box>
                                    <Typography className="paperHeading">
                                        Attachments
                                    </Typography>
                                </Box>
                                <Box className="main_paper2">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} lg={6} xl={6}>
                                            <Paper className="fourth_grid_box">
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            gap: '12px'
                                                        }}
                                                    >
                                                        <Box>
                                                            <img src={folderIcon} alt="folderIcon" />
                                                        </Box>
                                                        <Typography className="attachmentHeaderTypo">Upload Scaling</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Typography className="uploadTypo">Uploaded Files: </Typography>&nbsp;
                                                        <Typography className="uploadDataTypo">{files.length}</Typography>
                                                    </Box>
                                                </Box>

                                                {files.map((file: any, index: any) => this.renderFileBlock(file, index))}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} xl={6}>
                                            <Paper className="fourth_grid_box">
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            gap: '12px'
                                                        }}
                                                    >
                                                        <Box>
                                                            <img src={folderIcon} alt="folderIcon" />
                                                        </Box>
                                                        <Typography className="attachmentHeaderTypo">M & E Drawings</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Typography className="uploadTypo">Uploaded Files: </Typography>&nbsp;
                                                        <Typography className="uploadDataTypo">{files.length}</Typography>
                                                    </Box>
                                                </Box>

                                                {files2.map((file: any, index: any) => this.renderFileBlock(file, index))}
                                            </Paper>
                                        </Grid>




                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                        </MyProfileHeader>
                    </MainWrapper>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
    "& .main_box": {
        marginTop: '24px'
    },
    "& .main_paper": {
        marginTop: '32px',
        marginBottom: '32px',
        borderRadius: '12px'
    },
    "& .main_paper1": {
        marginTop: '16px',
        marginBottom: '32px',
        borderRadius: '12px'
    },
    "& .main_paper2": {
        marginTop: '16px',
        marginBottom: '24px',
        borderRadius: '12px'
    },
    "& .first_grid_box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '0px 24px'
    },
    "& .second_grid_box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '24px 24px'
    },
    "& .third_grid_box": {
        padding: '16px 24px'
    },
    "& .fourth_grid_box": {
        padding: '16px 20px',
        borderRadius: '8px'
    },
    "& .backButtonTypo": {
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
    },
    "& .mainTitleTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
    },
    "& .paperHeading": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'left',
        marginTop: '-7px'
    },
    "& .paperHeading2": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'left',
    },
    "& .paperFirstHeadingTypo": {
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left'
    },
    "& .paperFirstHeadingTwoTypo": {
        marginTop: '8px',
        color: '#237182',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'Right'
    },
    "& .paperFirstHeadingTwoTypoRight": {
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'Right'
    },
    "& .paperFirstDataTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
    },
    "& .paperFirstDataTwoTypo": {
        color: '#1E293B',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '26px',
        textAlign: 'right',
    },
    "& .paperWarnDataTypo": {
        color: '#DC2626',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'left'
    },
    "& .second_grid_inner_box": {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '6px'
    },
    "& .paperAddPaymentPlanTypo": {
        color: '#237182',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .thirdGridHeadingTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    },
    "& .thirdGridDataTypoBox": {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '4px'
    },
    "& .thirdGridDataTypoBox2": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .thirdGridDataTypo": {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    },
    "& .thirdGridDataTypoBold": {
        marginTop: '10px',
        color: '#1E293B',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    },
    "& .viewAllTaskList": {
        color: '#237182',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'},

    "& .attachmentHeaderTypo": {color: '#0F172A',fontFamily: 'Poppins',
        fontSize: '16px',fontWeight: 600,
        lineHeight: '24px',textAlign: 'left',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',
    },
    "& .uploadTypo": {color: '#334155',
        fontFamily: 'Poppins',fontSize: '14px',fontWeight: 400,lineHeight: '22px',
        textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',
      },
    "& .uploadDataTypo": {color: '#237182',
        fontFamily: 'Poppins',fontSize: '16px',fontWeight: 600,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'},
    "& .fileNameTypo": {color: '#0F172A',
        fontFamily: 'Poppins',fontSize: '14px',fontWeight: 400,lineHeight: '22px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',},
    "& .fileSizeTypo": {
        color: '#64748B',fontFamily: 'Poppins',fontSize: '12px',fontWeight: 400,
        lineHeight: '16px',textAlign: 'left',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',},
    "& .editButtonTypo":{
        cursor:'pointer',color:'#237182',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 600,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .inLineBox":{
        marginTop: '40px',display:'flex',
        flexDirection:'row',alignItems:'center',
        gap:'24px'
    },
    "& .inLineBox2":{
        display:'flex',flexDirection:'row',
        alignItems:'end',gap:'24px',
        marginTop: '-5px'
    },
    "& .taskDataTypo":{
        color:'#475569',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .inLineBoxUpper":{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    "& .collapseList":{
        marginBottom:'-3px',
        color:'#237182',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .showLessBox":{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'8px'
    },
  
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .title": {
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A",
        fontFamily: "Poppins",
        lineHeight: "32px",
        letterSpacing: "-0.005em"
      },
    "& .cancelButton": {
        backgroundColor: "#E2E8F0",
        color: "#325962",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "96px",
        fontWeight:600,
      },
      "& .confirmButton": {
        background: "#237182",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "100%",
        padding: "10px 16px 10px 16px",
        fontWeight:600,
      },
      "& .dialogPaper":{
        borderRadius:'12px',
        border:'1px solid #237182',
        backgroundColor:'#F0F5F7',
        padding:'24px'
      },
      "& .dialodDescTypo":{
        color:'#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .dialogLabel":{
        "& .MuiFormControlLabel-label":{
        color:'#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        }
      }
   
   
}));

const CustomDialogSelectPaymentPlan = styled(Dialog)(({ theme }) => ({
    "& .title": {fontWeight: 700,
        fontSize: "24px",color: "#0F172A",
        fontFamily: "Poppins",lineHeight: "32px",
        letterSpacing: "-0.005em"
      },
    "& .cancelButton": {
        backgroundColor: "#E2E8F0",color: "#325962",
        fontSize: "16px",fontFamily: "Poppins",
        textTransform: "none",height: "56px",
        borderRadius: "8px",width: "96px",
        fontWeight:600,
      },
      "& .confirmButton": {marginBottom:'-15px',
        background: "#237182",color: "#FFFFFF",
        fontSize: "16px",fontFamily: "Poppins",
        textTransform: "none",height: "56px",
        borderRadius: "8px",width: "100%",
        padding: "10px 16px 10px 16px",fontWeight:600,
      },
      "& .dialogPaperSelected":{borderRadius:'12px',border:'1px solid #237182',
        backgroundColor:'#F0F5F7',padding:'24px'
      },
      "& .dialogPaperNotSelected":{borderRadius:'12px',border:'1px solid #E2E8F0',
        backgroundColor:'#FFFFFF',padding:'24px'
      },
      "& .dialodDescTypo":{color:'#334155',fontFamily: 'Poppins',
        fontSize: '16px',fontWeight: 400,
        lineHeight: '24px',textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .dialogLabel":{color:'#0F172A',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 600,lineHeight: '24px',textAlign: 'left',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',
      },
   
}));
const CustomDialogMilestonesPaymentPlan = styled(Dialog)(({ theme }) => ({
    "& .title": {
        marginTop:'-5px',
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A",
        fontFamily: "Poppins",
        lineHeight: "32px",
        letterSpacing: "-0.005em"
      },
    "& .milestonesDialogDescription":{
        color:'#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    }, 
    "& .cancelButton": {
        backgroundColor: "#E2E8F0",
        color: "#325962",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "96px",
        fontWeight:600,
      },
      "& .confirmButton": {
        marginBottom:'-15px',
        background: "#237182",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "Poppins",
        textTransform: "none",
        height: "56px",
        borderRadius: "8px",
        width: "100%",
        padding: "10px 16px 10px 16px",
        fontWeight:600,
      },
      "& .dialogPaperSelected":{
        borderRadius:'12px',
        border:'1px solid #237182',
        backgroundColor:'#F0F5F7',
        padding:'24px'
      },
      "& .dialogPaperNotSelected":{
        borderRadius:'12px',
        border:'1px solid #E2E8F0',
        backgroundColor:'#FFFFFF',
        padding:'24px'
      },
      "& .dialodDescTypo":{
        color:'#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .dialogLabel":{
        color:'#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .table":{},
      
      
         "& .paymentOptionTitleTypo":{color:'#334155',fontFamily: 'Poppins',fontSize: '14px',fontWeight: 600,lineHeight: '22px',
          textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',},
      
          "& .paymentOptionTypo":{color:'#0F172A',fontFamily: 'Poppins',fontSize: '16px',
          fontWeight: 400,lineHeight: '24px',textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',},
      
          "& .addRetentionPeriodTypo":{color:'#0F172A',fontFamily: 'Poppins',fontSize: '16px',fontWeight: 600,
          lineHeight: '24px',textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',},
      
          "& .RetentionPeriodDescriptionTypo":{color:'#0F172A',fontFamily: 'Poppins',fontSize: '12px',
          fontWeight: 400,lineHeight: '16px',textAlign: 'left',textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',},
      
          "& .RetentionPeriodInputTypo":{color:'#475569',fontFamily: 'Poppins',fontSize: '14px',
        fontWeight: 600,lineHeight: '22px',textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',},
      
        "& .orTypo":{color:'#64748B',fontFamily: 'Poppins',fontSize: '12px',fontWeight: 400,lineHeight: '16px',textAlign: 'left',textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',marginTop:'7px'},
      
        "& .switchButtonTypo":{
        cursor:'pointer',
        color:'#237182',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'center',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .custom-input::placeholder": {
        color:'#94A3B8',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    }
   
   
}));

const CustomDialogValuationsPaymentPlan = styled(Dialog)(({ theme }) => ({
    "& .title": {marginTop:'-5px',fontWeight: 700,
        fontSize: "24px",color: "#0F172A",
        fontFamily: "Poppins",lineHeight: "32px",letterSpacing: "-0.005em"
      },
    "& .milestonesDialogDescription":{color:'#0F172A',fontFamily: 'Poppins',
        fontSize: '16px',fontWeight: 400,
        lineHeight: '24px',textAlign: 'left',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none'
    }, 
    "& .cancelButton": {backgroundColor: "#E2E8F0",color: "#325962",
        fontSize: "16px",fontFamily: "Poppins",
        textTransform: "none",height: "56px",
        borderRadius: "8px",width: "96px",
        fontWeight:600,
      },
      "& .confirmButton": {marginBottom:'-15px',
        background: "#237182",color: "#FFFFFF",
        fontSize: "16px",fontFamily: "Poppins",
        textTransform: "none",height: "56px",
        borderRadius: "8px",width: "100%",
        padding: "10px 16px 10px 16px",fontWeight:600,
      },
      "& .dialogPaperSelected":{borderRadius:'12px',
        border:'1px solid #237182',backgroundColor:'#F0F5F7',
        padding:'24px'
      },
      "& .dialogPaperNotSelected":{borderRadius:'12px',
        border:'1px solid #E2E8F0',backgroundColor:'#FFFFFF',
        padding:'24px'
      },
      "& .dialodDescTypo":{color:'#334155',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 400,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .dialogLabel":{color:'#0F172A',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 600,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .table":{},
      "& .paymentOptionTitleTypo":{color:'#334155',
          fontFamily: 'Poppins',fontSize: '14px',
          fontWeight: 600,lineHeight: '22px',
          textAlign: 'left',textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
      },
      "& .paymentOptionTypo":{color:'#0F172A',
          fontFamily: 'Poppins',fontSize: '16px',
          fontWeight: 400,lineHeight: '24px',
          textAlign: 'left',textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
      },
      "& .addRetentionPeriodTypo":{color:'#0F172A',
          fontFamily: 'Poppins',fontSize: '16px',
          fontWeight: 600,lineHeight: '24px',
          textAlign: 'left',textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
      },
      "& .RetentionPeriodDescriptionTypo":{color:'#0F172A',
          fontFamily: 'Poppins',fontSize: '12px',
          fontWeight: 400,lineHeight: '16px',
          textAlign: 'left',textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
      },
      "& .RetentionPeriodInputTypo":{color:'#475569',
        fontFamily: 'Poppins',fontSize: '14px',
        fontWeight: 600,lineHeight: '22px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
      },
      "& .orTypo":{color:'#64748B',
        fontFamily: 'Poppins',fontSize: '12px',
        fontWeight: 400,lineHeight: '16px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',marginTop:'7px'
      },
      "& .switchButtonTypo":{cursor:'pointer',
        color:'#237182',fontFamily: 'Poppins',
        fontSize: '16px',fontWeight: 600,
        lineHeight: '24px',textAlign: 'center',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none',
      },
      "& .custom-input::placeholder": {color:'#94A3B8',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 400,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .addAnotherInvoiceTypo":{color:'#237182',fontFamily: 'Poppins',
        fontSize: '16px',fontWeight: 600,
        lineHeight: '24px',textAlign: 'left',
        textUnderlinePosition: 'from-font',textDecorationSkipInk: 'none'
    },
    "& .tableFooterTypo":{color:'#0F172A',
        fontFamily: 'Poppins',fontSize: '16px',
        fontWeight: 400,lineHeight: '24px',
        textAlign: 'left',textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    }}));

const CustomViewAndAddMemberButton = styled(Button)(({ theme }) => ({

    width: "100%", height: "44px",
    color: '#237182',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: "capitalize"
}));
const CustomSaveDraftButton = styled(Button)(({ theme }) => ({

    width: "100%", height: "44px",
    padding: "10px 16px", gap: "8px",
    borderRadius: "8px", background: "#E0EDF0",
    color: "#325962",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: "capitalize"
}));
const CustomGenerateQuoteButton = styled(Button)(({ theme }) => ({

    width: "100%", height: "44px",
    padding: "10px 16px", gap: "8px",
    borderRadius: "8px", background: "#237182",
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: "capitalize"
}));
interface CustomTextFieldProps extends OutlinedTextFieldProps {
    hasValue: boolean;
  }
export const CustomTextField = styled(({ ...otherProps }: CustomTextFieldProps) => (
    <TextField {...otherProps} />
  ))(({ hasValue }: { hasValue: boolean }) => ({
    "& .MuiOutlinedInput-root": {
      height: '44px',
      borderRadius: '8px',
      borderColor: '#CBD5E1',
      backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
      "& fieldset": {
        borderColor: "#CBD5E1",
      },
      "&:hover fieldset": {
        border: "1px solid #CBD5E1",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #CBD5E1",
      },
    },
  }));
 const CustomSelect = styled(Select)(({theme}:any) => ({
    border:'1px solid #CBD5E1',
    borderRadius:'8px',
    height:'44px',
    padding:'10px 8px 10px 8px'

 }))
  const SaveButton = styled(Button)(({ disabled, theme }:any) => ({

     width:'100%',
    fontFamily: "Poppins",
    backgroundColor: disabled ? "#F1F5F9" : "#237182",
    color: disabled ? "#64748B" : "#FFFFFF",
    textTransform: "none",
    display:"flex",
    fontSize: "16px",
    fontWeight: 600,
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#237182",
    },
    border: "1px solid #C5E0E7",
    letterSpacing: 0,
    boxShadow: "none",
    borderWidth:0,
  }));
  const CancelButton = styled(Button)({
    width:'100%',
    backgroundColor: "#E2E8F0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "44px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    fontWeight:600,
    border: "1px solid #C5E0E7",
    letterSpacing: 0,
    boxShadow: "none",
    borderWidth:0,
  });
  const CustomSearchField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        border:'1px solid #CBD5E1',height:'32px',padding:'10px 8px 10px 8px',borderRadius:'8px',
      '&:hover': {
        border: 'none', 
      },
    },
  });
  const AddButton = styled(Button)({
    backgroundColor: "#237182",
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "16px",
    height: "32px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "6px 10px 6px 10px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth:0,
    '&:hover': {
      backgroundColor: '#237182', 
    },

  });
  const CancelButton2 = styled(Button)({
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    color: "#325962",
    fontWeight: 600,
    fontSize: "16px",
    height: "32px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "6px 10px 6px 10px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth:0,
  });
  const AdjustCostButton = styled(Button)(() => ({

   width:'fit-content',
   fontFamily: "Poppins",
   backgroundColor:'#E0EDF0',
   color: '#325962',
   textTransform: "none",
   display:"flex",
   fontSize: "16px",
   fontWeight: 600,
   height: "32px",
   lineHeigh:'24px',
   padding: "6px 10px 6px 10px",
   borderRadius: "8px",
   transform:'none',
  //  "&:hover": {
  //    backgroundColor: "#237182",
  //  },
   border: "1px solid #C5E0E7",
   letterSpacing: 0,
   boxShadow: "none",
   borderWidth:0,
 }));

 
// Customizable Area End
