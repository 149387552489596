import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Button as MyButton,Modal, Paper,Box as MyBox, Stack} from "@mui/material";
import { pdfUpload,clearIcon, clearIconTwo, pdfLogo, warningIcon } from "./assets";
import Dropzone, {DropzoneState} from 'react-dropzone';
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  disableUploadBtn = () => {
    if(this.state.activeFileType !== null && this.state.pdfData.file_name) {
      return false
    } else return true
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box >
     
        <Modal open={this.state.toggleUploadModal} data-test-id='uploadPdfModal'>
            <Box sx={webStyles.modalBox}>
                <Box sx={webStyles.headerBox}>
                  <Typography style={webStyles.title}>
                    {configJSON.uploadFileText}
                  </Typography>
                  <img
                    data-test-id='close-modal-icon'
                    src={clearIcon}
                    style={webStyles.closeIcon}
                    onClick={() => this.handleToggleModal(false)}
                    alt="closeIcon"
                  />
              </Box>
              <Box>
                {
                  !this.state.pdfData.file_name &&
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 1){
                      this.handleMoreFiles()
                    }
                  else  {
                    const file = acceptedFiles[0];
                    this.updatePdfFile({ target: { files: [file] }});
                  }
                  }}
                  data-test-id="dropzone"
                    >
                      {({ getRootProps, getInputProps }: DropzoneState) => (
                        <Paper
                          elevation={0}
                          sx={webStyles.dropzonePaper}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                            <>
                              <label htmlFor="contained-button-file" style={{ ...webStyles.label, color: '#334155' }}>
                                <Typography variant="body1"><strong>{configJSON.dragDropText}</strong></Typography>
                              </label>
                              <Typography variant="body1">
                                <Typography style={webStyles.labelText}>{configJSON.acceptableFormat}</Typography>
                                <Typography style={webStyles.labelText}>{configJSON.maxSize}</Typography>
                              </Typography>
                             
                            </>
                        </Paper>
                      )}
                </Dropzone>
                }
                 {this.state.uploadFileSizeErrorMsg && (
                  <Stack direction={'row'} alignItems={'center'} gap={1} marginTop={1}>
                    <img src={warningIcon} height={17} width={19} />
                  <Typography  style={webStyles.errorMsg}>
                    {this.state.uploadFileSizeErrorMsg}
                  </Typography>
                  </Stack>
                )}
              </Box>
                {this.state.pdfData.file_name && 
                <MyBox sx={webStyles.fileUploadContainer}>
                  <Typography style={webStyles.attachFileText}>
                    {configJSON.attachedFilesText}
                  </Typography>
                  <Box sx={webStyles.innerBox}>
                    <Box style={webStyles.fileInfoContainer}>
                      <Box sx={webStyles.pdfBox}>
                        <img src={pdfLogo} alt="pdf" style={webStyles.fileLogo} />
                      </Box>
                      <Box sx={webStyles.fileContainer}>
                        <Typography style={webStyles.fileName}>
                          {this.state.pdfData.file_name}
                        </Typography>
                        <Typography style={webStyles.fileSize}>
                          {this.renderUploadedFileSize(this.state.pdfData.size)}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      onClick={this.handleFileDelete}
                      data-test-id="deletePdfFile"
                    >
                      <img src={clearIconTwo} alt="delete" style={webStyles.deleteIcon} />
                    </Button>
                  </Box>
                </MyBox>
                }
            <Box marginTop={1}>
              <Typography style={webStyles.typography}>
                {configJSON.fileTypeText}
              </Typography>
              <Box sx={webStyles.box}>
                <MyButton data-test-id='electricalBtn' sx={this.state.activeFileType === 0 ?  webStyles.fileTypeButtons : webStyles.button} disableRipple onClick={() => this.handleActiveTabs(0)} >
                  {configJSON.electrical}
                </MyButton>
                <MyButton data-test-id='heatingBtn' sx={this.state.activeFileType === 1 ?  webStyles.fileTypeButtons : webStyles.button} disableRipple onClick={() => this.handleActiveTabs(1)} >
                  {configJSON.heating}
                </MyButton>
                <MyButton data-test-id='plumbingBtn' sx={this.state.activeFileType === 2 ?  webStyles.fileTypeButtons  : webStyles.button} disableRipple onClick={() => this.handleActiveTabs(2)} >
                  {configJSON.plumbing}
                </MyButton>
              </Box>
            </Box>
            
            <Box style={webStyles.btnContainer}>
              <MyButton data-test-id='cancelBtn' sx={webStyles.cancelButton} disableRipple onClick={this.handleCancelModal}>
                {configJSON.cancelBtn}
              </MyButton>
              <MyButton  data-test-id='uploadPdfBtn' sx={ this.disableUploadBtn() ? webStyles.uploadButton: {...webStyles.uploadButton,color: 'white', backgroundColor: '#237182'}} disableRipple disabled={this.disableUploadBtn()} onClick={this.onClickUploadFile}>
                {configJSON.uploadBtn}
              </MyButton>
            </Box>
            </Box>
        </Modal>
        <MyButton
          sx={webStyles.uploadPdfbutton}
          data-test-id='upload-pdf-btn'
          startIcon={<img src={pdfUpload} style={webStyles.icon} />}
          onClick={() => this.handleToggleModal(true)}
        >
          {configJSON.uploadPdfBtn}
        </MyButton>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleSheet = {
  screen:{
    width:"100vw",
    height:"100vh",
    backgroundColor:"rgba(0, 0, 0, 0.5)",
  },
  imgModel:{
    width:"40%",
    height:"80%",
    border:"2px solid black",
    margin:"80px auto 0px auto",
    position:"relative",
  },
  closeImg:{
    position:"absolute",
    top:"-50px",
    right:"-50px",
    cursor: "pointer"
  }
}

const fileTypeBtn = {
  width: '100%',
  height: '40px',
  color: '#325962',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  fontFamily: 'Poppins',
  padding: '10px 12px 10px 12px',
  borderRadius: 50,
  textTransform: 'none',
  boxSizing: 'border-box',
  border: 'none',
  '&:hover': {
    color: '#325962',
    backgroundColor: '#E0EDF0',
  },
}

const webStyles = { 
  errorMsg:{color: '#DC2626', fontSize: '14px', lineHeight: '22px', fontWeight: 400, fontFamily: 'Poppins'},
  fileTypeButtons: {
    ...fileTypeBtn,
    border: '1px solid #D3E4E9',
    backgroundColor: '#E0EDF0',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '24px',
    width: 600,
    minHeight: 350,
    borderRadius: 12,
    backgroundColor: '#fff',
    paddingRight: '24px',
    paddingLeft: '24px',
    paddingBottom: '15px',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  title: {
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  closeIcon: {
    width: '10.99px',
    height: '10.99px',
    cursor: 'pointer',
  },
  dropzonePaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '4px 8px',
    flexDirection: 'column' ,
    backgroundColor:  '#F8FAFC',
    border: '2px dashed rgba(203, 213, 225, 1)',
    margin: '0 auto',
    marginTop: '10px',
    height: "140px",
    borderRadius: "2px",
    boxSizing: 'border-box',
    
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: 40
  },
  cancelButton: {
    width: '90px',
    height: '56px',
    color: '#325962',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Poppins',
    backgroundColor: '#E0EDF0',
    borderRadius: '8px',
    textTransform: 'none',
    '&:hover': {
      color: '#325962',
      backgroundColor: '#E0EDF0',
    },
  },
  uploadButton: {
    width: '91px',
    height: '56px',
    color: '#64748B',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    backgroundColor: '#F1F5F9',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#237182',
    },
  },
  boldText: {
    display: "inline-block",
    marginLeft: "5px",
    color: "rgba(35, 113, 130, 1)",
    fontWeight: "bold",
    verticalAlign: "middle",
  
  },
  labelText: {
    fontFamily: "Poppins",
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '14px',}as const,
  labelBrowse: {
    display: "inline-block",} as const,
  inlineIcon: {
    verticalAlign: "middle",
    color: "#237182",
    display: "inline-block",
    width:"20px"
  },
  fileUploadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  mainbox: {
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
    display: 'flex',
    height: '100vh',
    padding: 0,},
  rightBox: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "24px",
    width: "50%",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    borderBottomLeftRadius: "24px",
    padding: "48px",
    height: "100vh",
    overflowY: "auto",} as const,
  leftbox: {
    padding: "40px",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    height: "100vh",
    width: "50%",
    display: 'flex',} as const,
  logohereText: {
    color: "white",
    fontWeight: "bold",
    textAlign: 'left',
    fontSize: "30px", } as const,
  imgPic: {
    width:"70%",
  },
  labelContainer: {
    fontWeight: "600",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color:"#334155",
    marginRight: "20px",
    fontSize: "16px", },
  label: {
    verticalAlign: "middle",
    color: "#237182",
    cursor: 'pointer',
    gap: "5px",
    display: "inline-block",
    fontFamily: "Poppins"
  },
  fileInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  fileLogo: {
    width: '16.28px',
    height: '20px',
  }, 
  typography: {
    color: '#334155',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 2,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8FAFC',
    borderRadius: 50,
    height: '48px',
    padding: '2px 4px 2px 4px',
    width: '100%',
    boxSizing: 'border-box'
  },
  button: {
    ...fileTypeBtn
  },
  uploadPdfbutton: {
    textTransform: 'none',
    fontSize: '16px',
    borderRadius: '8px',
    width: '153px',
    height: '44px',
    fontFamily: 'Poppins',
    lineHeight: '24px',
    fontWeight: 600,
    backgroundColor: '#237182',
    color: '#ffffff',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#237182',
    },
  },
  icon: {
    height: '15.57px',
    width: '16px',
    marginRight: 1,
  },
  attachFileText: {
    color: '#334155',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 2,
  },
  innerBox: {
    padding: '6px 4px 6px 8px',
    boxShadow: '0px 2px 8px 1px rgba(0,0,0,0.2)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop:2
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pdfBox: {
    backgroundColor: '#E0EDF0',
    borderRadius: 4,
    width: 38,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileName: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    color: '#0F172A',
  },
  fileSize: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: '#64748B',
  },
  deleteIcon: {
    width: '15.38px',
    height: '15.38px',
  },
};
// Customizable Area End
