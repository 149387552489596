// Customizable Area Start

import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Paper,
  Modal,
  TextField
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import DocumentationController, {
  Props,
  GalleryMedias,
} from "./DocumentationController";

export default class Documentation extends DocumentationController {
  constructor(props: Props) {
    super(props);
  }

  menuOptionsModal = () => {
    return (
      <Modal
        data-test-id="menu-modal"
        open={!!this.state.fileMenu}
        onClose={() => this.handleMenuModal(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={webStyle.docMenuOption as CSSProperties}>
          <Button
            onClick={() => this.handleRenameModal(this.state.fileMenu)}
            data-test-id="menu-rename-button"
            style={{
              color: 'rgb(66, 65, 65)',
              padding: '20px',
              display: 'block',
              width: '100%',
              textAlign: 'left',
            }}
            disableRipple>
            <b>Rename</b></Button>
          <Button
            data-test-id="menu-delete-button"
            onClick={() => this.handleDeleteModal(this.state.fileMenu)}
            style={{
              color: 'rgb(66, 65, 65)',
              padding: '20px',
              display: 'block',
              width: '100%',
              textAlign: 'left',
            }}
            disableRipple>
            <b>Delete</b>
          </Button>
        </div>
      </Modal>
    )
  }

  renameFileModal = () => {
    return (
      <Modal
        open={!!this.state.renameModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={webStyle.renameModal as CSSProperties}>
          <label style={{ padding: "20px", display: "block", fontWeight: 700 }}>
            Rename
          </label>
          <TextField
            data-test-id="rename-field"
            value={this.state.renameValue}
            onChange={this.handleRename}
            id="outlined-basic" variant="outlined"
            style={{ width: "90%", border: "1px solid black", borderRadius: "5px" }}
          />
          <Box style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
            <Button
              data-test-id="rename-cancel-button"
              onClick={() => this.handleRenameModal(null)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                color: 'rgb(66, 65, 65)',
                border: '2px solid #dd9865',
                backgroundColor: "white"
              }}
              variant="contained" component="span">
              Cancel
            </Button>
            <Button
              data-test-id="rename-button"
              onClick={() => this.renameFileAndFolder(this.state.fileMenu)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                border: '2px solid #dd9865',
                backgroundColor: "#dd9865",
                color: "white",
              }}
              variant="contained" component="span">
              Rename
            </Button>
          </Box>
        </div>
      </Modal>
    )
  }

  deleteModal = () => {
    return (
      <Modal
        open={!!this.state.deleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={webStyle.renameModal as CSSProperties}>
          <label style={{ padding: "20px", display: "block", fontWeight: 700 }}>
            Do you want to delete this{this.state.fileMenu?.file === "folder" ? " folder ?" : " file ?"}
          </label>
          <Box style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
            <Button
              data-test-id="close-delete-modal-button"
              onClick={() => this.handleDeleteModal(null)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                color: 'rgb(66, 65, 65)',
                border: '2px solid #dd9865',
                backgroundColor: "white"
              }}
              variant="contained" component="span">
              No
            </Button>
            <Button
              data-test-id="delete-button"
              onClick={() => this.deleteFolderAndFiles(this.state.fileMenu)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                border: '2px solid #dd9865',
                backgroundColor: "#dd9865",
                color: "white",
              }}
              variant="contained" component="span">
              Yes
            </Button>
          </Box>
        </div>
      </Modal>
    )
  }

  addFolderModal = () => {
    return (
      <Modal
        open={this.state.addFolderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={webStyle.addFolder as CSSProperties}>
          <label style={{ padding: "20px", display: "block", fontWeight: 700 }}>Add Folder</label>
          <TextField
            data-test-id="folder-name-field"
            onChange={this.handleFolderName}
            id="outlined-basic"
            variant="outlined"
            style={{ width: "90%", border: "1px solid black", borderRadius: "5px" }} />
          <Box style={{ display: "flex", justifyContent: "space-evenly", marginTop: "20px" }}>
            <Button
              data-test-id="close-add-folder-modal-button"
              onClick={() => this.handleAddFolderModal(false)}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                color: 'rgb(66, 65, 65)',
                border: '2px solid #dd9865',
                backgroundColor: "white"
              }}
              variant="contained" component="span">
              Cancel
            </Button>
            <Button
              data-test-id="add-folder-button"
              onClick={this.createFolderOpen}
              style={{
                width: '170px',
                textTransform: 'capitalize',
                border: '2px solid #dd9865',
                backgroundColor: "#dd9865",
                color: "white",
              }}
              variant="contained" component="span">
              Add Folder
            </Button>
          </Box>
        </div>
      </Modal>
    )
  }

  renderLoaderWeb() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <CircularProgress style={{color: "#0000FF"}}/>
      </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ overflowX: 'hidden' }}>
          <Container maxWidth={"xl"}>
            <div style={{ textAlign: 'right', margin: '30px 0' }}>
              <label htmlFor="uploadFile">
                <input
                  data-test-id="upload-files"
                  onChange={this.handleUpload}
                  style={{ display: 'none' }}
                  id="uploadFile"
                  name="uploadFile"
                  type="file"
                  multiple
                />
                <Button
                  color="secondary"
                  style={{
                    width: '170px',
                    textTransform: 'capitalize',
                    color: 'rgb(66, 65, 65)',
                    border: '2px solid #dd9865'
                  }}
                  variant="outlined" component="span">
                  Upload Document
                </Button>
              </label>
              <Button
                data-test-id="add-folder-modal-button"
                onClick={() => this.handleAddFolderModal(true)}
                variant="outlined"
                color="secondary"
                style={{
                  marginLeft: "15px", 
                  width: '170px',
                  textTransform: 'capitalize',
                  color: 'rgb(66, 65, 65)',
                  border: '2px solid #dd9865'
                }}
              >
                Add Folder</Button>
            </div>
            <Grid container spacing={3} style={{ marginTop: "30px" }}>
              {this.state.allDocuments?.map((element: GalleryMedias, index: number) => {
                return (
                  <Grid item key={index}>
                    <Paper style={{ background: 'rgb(234, 231, 231)', borderRadius: '15px', position: "relative" }} >
                      <div style={{ textAlign: "center", padding: "15px" }}
                        data-test-id="folder-button"
                        onDoubleClick={() => this.folderNavigate(element)}>
                        {element.attributes?.folder_name ?
                          <FolderIcon style={{ fontSize: "200px", color: "#DDB092" }} /> :
                          <InsertDriveFileIcon style={{ fontSize: "200px", color: "#787878" }} />}
                      </div>
                      <div>
                        <button
                          data-test-id="menu-options-button"
                          onClick={() => this.handleMenuModal({
                            id: element.gallery_id ?? element.attributes.gallery?.id,
                            file: !!element.gallery_id ? "file" : "folder",
                            blob_id: element.blob_id ?? element.id,
                            fileAndFolderName: element.filename ?? element.attributes.folder_name,
                          })}
                          type="button"
                          style={webStyle.dotOptions as CSSProperties}>
                          <MoreVertIcon fontSize="large" />
                        </button>
                      </div>
                    </Paper>
                    <Typography variant="h6" component="h2" align="center" style={{ paddingTop: '10px', width: 200, wordBreak: "break-all" }}>
                      {element.attributes?.folder_name ?? element.filename}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </div>
        {this.state.loader && this.renderLoaderWeb()}
        {this.deleteModal()}
        {this.addFolderModal()}
        {this.renameFileModal()}
        {this.menuOptionsModal()}
      </ThemeProvider>
    );
  }
}

const webStyle = {
  
  folderImage: {
    width: '100%'
  },
 
  addFolder: {
    background: '#fff',
    maxWidth: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    position: 'absolute',
    borderRadius: '15px',
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "20px"
  },
  docMenuOption: {
    background: '#fff',
    width: '200px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    borderRadius: '15px',
  },
  renameModal: {
    background: '#fff',
    maxWidth: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    position: 'absolute',
    borderRadius: '15px',
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "20px"
  },
  dotOptions: {
    backgroundColor: 'transparent',
    border: '0',
    width: '22%',
    textAlign: 'right',
    paddingBottom: '10px',
    position: 'absolute',
    bottom: "0",
    right: "-3px",
    cursor: "pointer"
  },
  
};

// Customizable Area End
